import { useParams } from "react-router-dom";
import { useDashboardContext } from "../context-state/DashboardContext.jsx";
import { useEffect } from "react";

export const useOverrideMsg = () => {
    const { eventId } = useParams()
    const { eventContext, ticketContext, overrideMsgContext } = useDashboardContext()
    const { getEvent } = eventContext
    const { getTicketsOfEvent } = ticketContext
    const { setDashboardOverrideMsg } = overrideMsgContext
    const event = getEvent(eventId)
    const ticketsOfEvent = getTicketsOfEvent(eventId)

    useEffect(() => {
        if (event) {
            if (event.isSeatsEvent) {
                const ticketsOfEvent = getTicketsOfEvent(eventId)
                if (ticketsOfEvent && !ticketsOfEvent?.length) {
                    setDashboardOverrideMsg("שימו לב! אין מחיר לכרטיסים ב'מפת המושבים'")
                    return
                }
            }
        }
        setDashboardOverrideMsg(null)
    }, [ticketsOfEvent, event])
}
