import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

export const SmsCampaignContext = createContext(null);

SmsCampaignContext.propTypes = {
    children: PropTypes.node,
};

export function SmsCampaignProvider({children}) {

    const [smsCampaignList, setSmsCampaignList] = useState(null)

    return (
        <SmsCampaignContext.Provider
            value={{
                smsCampaignContext: {smsCampaignList, setSmsCampaignList},
            }}
        >
            {children}
        </SmsCampaignContext.Provider>
    );
}

export const useSmsCampaignContext = () => {
    const context = useContext(SmsCampaignContext);

    if (!context) throw new Error('useSmsCampaignContext context must be use inside SmsCampaignProvider');

    return context;
};
