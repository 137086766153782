import { EventBreadcrumbs } from "../../common/EventBreadcrumbs";
import { useLocales } from "../../../locales";
import {
    Box, Button, Dialog, DialogContent, DialogTitle,
    IconButton, InputAdornment,
    Stack,
    Table,
    TableBody,
    TableContainer,
    Tooltip, Typography
} from "@mui/material";
import {
    emptyRows,
    TableEmptyRows,
    TableHeadCustom, TableNoData,
    TablePaginationCustom,
    TableSelectedAction,
    getComparator,
    useTable
} from "../../../components/minimal/table";
import React, { useEffect, useState } from "react";
import Iconify from "../../../components/minimal/iconify";
import { CustomTextField } from "../../../components/minimal/custom-input";
import { LinkTableRow } from "./LinkTableRow";
import { LinkForm } from "./LinkForm";
import ConfirmDialog from "../../../components/minimal/confirm-dialog";
import { LoadingButton } from "@mui/lab";
import { LinksUtm } from "../../../consts";
import { useDashboardContext } from "../../../context-state/DashboardContext";
import * as API from "../../../api";
import { useSnackbar } from "../../../components/minimal/snackbar";
import { useParams } from "react-router-dom";
import { RoleBasedComponentGuard, ROLES_LABELS } from "../../common/guards/RoleGuard.jsx";


const TABLE_HEAD = [
    { id: 'name', label: 'שם' },
    { id: 'description', label: 'תיאור' },
    { id: 'utm', label: 'לינק' },
    { id: 'createdAt', label: 'תאריך יצירה' },
    { id: 'tickets', label: 'כרטיסים' },
    { id: 'isActive', label: 'סטאטוס' },
    { id: 'sessions', label: 'סאשנים' },
    { id: 'usersViews', label: 'יוזרים' },
    { id: 'sold_count', label: 'כרטיסים נמכרו' },
    { id: 'refund_count', label: 'כרטיסים זוכו' },
    { id: 'cancel_count', label: 'כרטיסים בוטלו' },
    { id: 'pending_count', label: 'כרטיסים ממתינים לאישור' },
]

export const LinksSection = ({ currentEvent }) => {

    const { translate } = useLocales()
    const { enqueueSnackbar } = useSnackbar();
    const { subId, eventId } = useParams()
    const { utmContext, linkContext } = useDashboardContext()
    const { getLinksOfEvent, getLink, deleteLinks, deleteEventToLinksRelation } = linkContext
    const { getUtm } = utmContext

    const table = useTable({ defaultRowsPerPage: 10, defaultOrder: 'asc', defaultOrderBy: 'createdAt' });
    const { dense, page, order, orderBy, rowsPerPage, selected, onSelectRow, onSelectAllRows, onSort, onChangePage, onChangeRowsPerPage } = table;

    const { createdAt } = currentEvent
    const linksIds = getLinksOfEvent(currentEvent._id)
    const links = linksIds.map(getLink)

    const primaryLink = {
        _id: "__primary__",
        name: "ראשי",
        utm: LinksUtm.PRIMARY,
        is_enabled: true,
        createdAt
    }

    const eventLinks = [
        primaryLink,
        ...links
    ]

    const denseHeight = dense ? 52 : 72;
    const [tableData, setTableData] = useState(eventLinks);
    const [filterName, setFilterName] = useState('');
    const [isLoading, setIsLoading] = useState(false)

    const [isFormDialogOpen, setIsFormDialogOpen] = useState(false)

    const [targetLinkToEdit, setTargetLinkToEdit] = useState(null)
    const [targetLinkToDelete, setTargetLinkToDelete] = useState(null)
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false)

    const dataFiltered = applyFilter({ inputData: tableData, comparator: getComparator(table.order, table.orderBy), filterName });

    const dataInPage = dataFiltered.slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage);

    useEffect(() => {
        if (JSON.stringify(eventLinks) !== JSON.stringify(tableData)) {
            setTableData(eventLinks)
        }
    }, [links])


    const handleFilterName = (event) => {
        table.setPage(0);
        setFilterName(event.target.value);
    }

    useEffect(() => {
        if (targetLinkToDelete) {
            setOpenConfirmDelete(true)
        }
    }, [targetLinkToDelete])

    useEffect(() => {
        if (!openConfirmDelete) {
            setTargetLinkToDelete(null)
        }
    }, [openConfirmDelete])


    const handleDelete = async () => {

        // TODO - finish this after Dan will be done with the server to close this feature.
        setIsLoading(true)
        try {
            let linksToDelete = []

            if (targetLinkToDelete) {
                linksToDelete = [targetLinkToDelete]
            } else if (selected.length) {
                linksToDelete = selected
            }

            if (!linksToDelete?.length) {
                return
            }


            const deletePromises = linksToDelete.map(linkId => API.deleteLinkOfEvent({ accountId: subId, eventId, linkId }))
            await Promise.all(deletePromises)

            /* On successfully delete */
            deleteEventToLinksRelation(eventId, linksToDelete)
            deleteLinks(linksToDelete)

            enqueueSnackbar(translate('snackbar.links_deleted_successfully'));
            onSelectAllRows(false, [])
            setOpenConfirmDelete(false)

            // }
            //
            // const newLinksState = links.filter(link => !linksToDelete.includes(link._id))
            // const res = await API.updateOrCreateEventLinks({ accountId: subId, eventId, linksArray: newLinksState })
            // if (!res?.data) {
            //     throw Error('failed to update links')
            // }
            // updateEvent(res.data)
            // enqueueSnackbar(translate('snackbar.links_deleted_successfully'));
            //
            //
        } catch (e) {
            enqueueSnackbar(translate('general_error'), { variant: 'error' })
            console.error(e);
        } finally {
            setIsLoading(false)
        }



    }


    const setEditLink = (linkIdToEdit) => {
        setTargetLinkToEdit(linkIdToEdit)
        setIsFormDialogOpen(true)
    }

    const closeFormDialog = () => {
        setIsFormDialogOpen(false)
        setTimeout(() => setTargetLinkToEdit(null), 300)
    }

    return (
        <Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <EventBreadcrumbs
                    event={currentEvent}
                    heading="לינקים"
                />
                <RoleBasedComponentGuard minimumRoleAllowedAccess={ROLES_LABELS.EDITOR}>
                    <Button
                        onClick={() => setIsFormDialogOpen(true)}
                        variant="contained"
                        sx={{ height: 'fit-content' }}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                        {translate('dashboard.events.create_new_link')}
                    </Button>
                </RoleBasedComponentGuard>
            </Stack>

            <Stack sx={{ mb: 2 }}>
                <CustomTextField
                    width={220}
                    placeholder={translate('dashboard.events.search')}
                    size="small"
                    onChange={handleFilterName}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>


            <>
                <Box sx={{ px: 1, position: 'relative', borderRadius: 1.5, bgcolor: 'background.neutral' }}>
                    <TableSelectedAction
                        dense={dense}
                        numSelected={selected.length}
                        rowCount={tableData.length}
                        customMaxSize={tableData.length - 1}
                        onSelectAllRows={(checked) => onSelectAllRows(checked, tableData.filter(link => link.utm !== LinksUtm.PRIMARY).map((row) => row._id))}
                        action={
                            <RoleBasedComponentGuard minimumRoleAllowedAccess={ROLES_LABELS.EDITOR}>
                                <Tooltip title={translate('dashboard.events.delete')}>
                                        <IconButton color="primary" onClick={() => setOpenConfirmDelete(true)}>
                                            <Iconify icon="eva:trash-2-outline" />
                                        </IconButton>
                                    </Tooltip>
                            </RoleBasedComponentGuard>
                        }
                        sx={{
                            pl: 1,
                            pr: 2,
                            top: 8,
                            left: 8,
                            right: 8,
                            width: 'auto',
                            borderRadius: 1,
                        }}
                    />

                    <TableContainer>
                        <Table
                            size="medium"
                            sx={{
                                minWidth: 960,
                                borderCollapse: 'separate',
                                borderSpacing: '0 8px',
                                '& .MuiTableCell-head': {
                                    boxShadow: 'none !important',
                                },
                            }}
                        >
                            <TableHeadCustom
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={tableData.length}
                                numSelected={selected.length}
                                onSort={onSort}
                                onSelectAllRows={(checked) => onSelectAllRows(checked, tableData.filter(link => link.utm !== LinksUtm.PRIMARY).map((row) => row._id)) }
                                sx={{
                                    '& .MuiTableCell-head': {
                                        bgcolor: 'transparent',
                                    },
                                }}
                            />

                            <TableBody>
                                {dataFiltered
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        const relevantUtm = getUtm(row.utm)
                                        return (
                                            <LinkTableRow
                                                key={row._id}
                                                row={row}
                                                utmData={relevantUtm}
                                                selected={selected.includes(row._id)}
                                                onSelectRow={() => onSelectRow(row._id)}
                                                onDeleteRow={() => setTargetLinkToDelete(row._id)}
                                                onEditRow={() => setEditLink(row._id) }
                                            />
                                        )
                                    })}

                                <TableEmptyRows
                                    height={denseHeight}
                                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                                />

                                <TableNoData isNotFound={!dataFiltered.length} />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <TablePaginationCustom
                    count={dataFiltered.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    dense={dense}
                    sx={{
                        '& .MuiTablePagination-root': { borderTop: 'none' },
                        '& .MuiFormControlLabel-root': { px: 0 },
                    }}
                />
            </>

            {/* Confirm Delete Dialog */}
            <ConfirmDialog
                open={openConfirmDelete}
                onClose={() => setOpenConfirmDelete(false) }
                title={translate('dashboard.events.delete_links')}
                action={
                    <LoadingButton loading={isLoading} variant="contained" color="error" onClick={handleDelete}>
                        {translate('dashboard.events.delete')}
                    </LoadingButton>
                }
            />

            {/* Edit/Create Link Dialog */}
            <Dialog fullWidth maxWidth="sm" open={isFormDialogOpen} onClose={closeFormDialog}>
                <DialogTitle sx={{ p: 2 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h5">
                            {targetLinkToEdit ? 'עדכון לינק' : 'יצירת לינק'}
                        </Typography>
                        <IconButton onClick={closeFormDialog}>
                            <Iconify width="24px" icon="eva:close-outline" />
                        </IconButton>
                    </Stack>
                </DialogTitle>

                <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
                    <LinkForm
                        onSuccess={closeFormDialog}
                        buttonText={targetLinkToEdit ? 'עדכן לינק' : 'צור לינק'}
                        link={targetLinkToEdit ? links.find(link => link._id === targetLinkToEdit) : null}
                    />
                </DialogContent>
            </Dialog>

        </Stack>
    )
}

function applyFilter({ inputData, comparator, filterName }) {
    const stabilizedThis = inputData.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    inputData = stabilizedThis.map((el) => el[0]);

    // Filter by free text, currently only by the name of the link
    if (filterName) {
        inputData = inputData.filter((link) => link.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1);
    }

    return inputData;
}
