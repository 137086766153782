import { createContext, useContext, useState } from "react";
import { extractId } from "../utils/transformers.js";

export const SeasonTicketContext = createContext(null)

export function SeasonTicketProvider({ children }) {

    const [seasonTicketMap, setSeasonTicketMap] = useState(null)
    /* Map relations, an object which maps higher entity to it relations - { idOfTheEntity: relatedEntitiesId[] }  */
    const [accountSellerToSeasonTicketMap, setAccountToSeasonTicketMap] = useState({})

    const updateManySeasonTicket = (seasonTickets) => {
        const newSeasonTicketMap = JSON.parse(JSON.stringify(seasonTicketMap ? seasonTicketMap : {}))
        seasonTickets.forEach(seasonTicket => { newSeasonTicketMap[seasonTicket._id] = seasonTicket })
        setSeasonTicketMap(newSeasonTicketMap)
    }
    const updateSellerAccountToSeasonTicketRelation = (sellerAccountId, seasonTickets) => {
        const ids = seasonTickets.map(extractId)
        const newAccountSellerToSeasonTicketMap = JSON.parse(JSON.stringify(accountSellerToSeasonTicketMap))
        const newArray = newAccountSellerToSeasonTicketMap[sellerAccountId]?.length ? [...newAccountSellerToSeasonTicketMap[sellerAccountId], ...ids] : ids
        accountSellerToSeasonTicketMap[sellerAccountId] = [ ...new Set(newArray)] // Force to remove duplications
        setAccountToSeasonTicketMap(accountSellerToSeasonTicketMap)
    }
    const getSeasonTicket = (id) => seasonTicketMap[id]
    const getSeasonTicketOfSellerAccount = (sellerAccountId) => accountSellerToSeasonTicketMap[sellerAccountId]


    return (
        <SeasonTicketContext.Provider
            value={{
                updateManySeasonTicket,
                updateSellerAccountToSeasonTicketRelation,
                getSeasonTicket,
                getSeasonTicketOfSellerAccount
            }}
        >
            {children}
        </SeasonTicketContext.Provider>
    )

}

export const useSeasonTicketContext = () => {
    const context = useContext(SeasonTicketContext)
    if (!context) {
        throw new Error('useSeasonTicketContext context must be used inside SeasonTicketProvider')
    }
    return context
}
