import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { APP_ENV, AWS_ACCESS_KEY_ID, AWS_CLOUDFROND_BASE_URL, AWS_BUCKET_NAME, AWS_REGION, AWS_SECRET_ACCESS_KEY } from "../config-global";

const client = new S3Client({ credentials: { accessKeyId: AWS_ACCESS_KEY_ID, secretAccessKey: AWS_SECRET_ACCESS_KEY }, region:  AWS_REGION });

export const S3Handler = {
    async uploadFile(file, ContentType, fileName) {
        fileName = fileName || file.name || 'asset'
        const fileKey = `${APP_ENV}_${Date.now()}_${fileName}`
        const fullPathToAsset = `${AWS_CLOUDFROND_BASE_URL}/${fileKey}`

        const command = new PutObjectCommand({ Body: file, Bucket: AWS_BUCKET_NAME, Key: fileKey, ContentType });
        await client.send(command);

        return fullPathToAsset
    },
    async uploadBase64(imageAsBase64) {
        const imageAsBlob = dataURItoBlob(imageAsBase64)
        return S3Handler.uploadFile(imageAsBlob)
    }
}

function dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for( let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}
