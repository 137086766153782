import PropTypes from 'prop-types';
// @mui
import { List, Stack } from '@mui/material';
// locales
import { useLocales } from '../../../../locales';
import { m } from 'framer-motion';
//
import { StyledSubheader } from './styles';
import NavList from './NavList';
import Label from "../../label";
import { useParams } from "react-router-dom";
import { useDashboardContext } from "../../../../context-state/DashboardContext";

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
};

export default function NavSectionVertical({ data, sx, ...other }) {
  const { translate } = useLocales();
  const { eventId } = useParams()
  const { eventContext } = useDashboardContext()
  const { getEvent } = eventContext
  const event = getEvent(eventId)

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && (
              <StyledSubheader style={{ justifyContent: "space-between", alignItems: "center", display: "flex" }} disableSticky>
                {`${translate(group.subheader)}`}
                {
                  (group.subheader === "event_management" && event)
                      ?
                      <Label sx={{ display: "inline-block", textOverflow: "ellipsis", maxWidth: "130px", overflow: "hidden", whiteSpace: "nowrap", lineHeight: 2 }} color="info">{event.name}</Label>
                      : null
                }
              </StyledSubheader>
            )}

            {group.items.map((list, i) => (
                <m.div
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: i * 0.06, duration: 0.3, ease: 'easeOut', }}
                    key={list.title + list.path}>
                  <NavList
                      data={list}
                      depth={1}
                      hasChild={!!list.children}
                  />
                </m.div>

            ))}
          </List>
        );
      })}
    </Stack>
  );
}
