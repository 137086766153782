import PropTypes from 'prop-types';
// @mui
import { Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/minimal/logo';
import Image from '../../components/minimal/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

AuthLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
};

export default function AuthLayout({ children, illustration, title, subTitle, onlyImage = true }) {
  return (
    <StyledRoot>

        <Logo
            sx={{
                zIndex: 9,
                position: 'absolute',
                right: 40,
                mt: { xs: 1.5, md: 5 },
                ml: { xs: 2, md: 5 },
            }}
        />

      <StyledContent>
         <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>


      { onlyImage
          ? <StyledSection sx={{ direction: "rtl", backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(/assets/images/auth/login.jpg)", backgroundSize: "cover", backgroundPosition: "center" }} />
          : <StyledSection>

              <Typography variant="h3" sx={{ maxWidth: 480, textAlign: 'center' }}>
                  {title}
              </Typography>

              {
                  subTitle ? <Typography variant="h6" sx={{ maxWidth: 480, mt: 2, textAlign: 'center' }}>{subTitle}</Typography> : null
              }

              <Image
                  disabledEffect
                  visibleByDefault
                  alt="auth"
                  src={illustration || '/assets/illustrations/illustration_dashboard.png'}
                  sx={{ maxWidth: 720, mt: 10 }}
              />

              <StyledSectionBg />
          </StyledSection>
      }




    </StyledRoot>
  );
}
