import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import localStorageAvailable from '../utils/minimal/localStorageAvailable';
//
import { defaultLang } from './config-lang';
//
import enLocales from './langs/en';
import heLocales from './langs/he';
import { getMarketPlaceName } from '../utils/market';

// ----------------------------------------------------------------------

let lng = defaultLang.value;

const storageAvailable = localStorageAvailable();

if (storageAvailable) {
  lng = localStorage.getItem('i18nextLng') || defaultLang.value;
}

/**
 *  some marketplaces have their own terminology that should be used in the language files
 * @param {*} langFile 
 * @returns 
 */
const insertMarketplaceTerminology = (langFile) => {
  // read terminology from the language files
  const marketplaceName = getMarketPlaceName();
  if (langFile[marketplaceName] && langFile[marketplaceName].terminology) {
    const marketplaceTerminology = langFile[marketplaceName].terminology;
    // for each key and value in the marketplaceTerminology object override the language file a key could is a string object representing the key in the language file like so 'key1.key2.key3'
    Object.keys(marketplaceTerminology).forEach((key) => {
      const keyParts = key.split('.');
      let currentObject = langFile;
      keyParts.forEach((part, index) => {
        if (index === keyParts.length - 1) {
          currentObject[part] = marketplaceTerminology[key];
        } else {
          if (!currentObject[part]) {
            currentObject[part] = {};
          }
          currentObject = currentObject[part];
        }
      });
    });
  }
  
  return langFile;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      he: { translations: insertMarketplaceTerminology(heLocales) },
      en: { translations: enLocales },
    },
    lng,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
