import { createContext, useContext, useState } from "react";

export const SellerPageContext = createContext(null);

export function SellerPageContextProvider({ children }) {

    const [account, setAccount] = useState(null)
    const [events, setEvents] = useState([])

    return (
        <SellerPageContext.Provider
            value={{
                accountContext: { account, setAccount },
                eventContext: { events, setEvents }
            }}
        >
            {children}
        </SellerPageContext.Provider>
    );
}


export const useSellerPageContext = () => {
    const context = useContext(SellerPageContext);

    if (!context) throw new Error('useSellerPageContext context must be use inside SellerPageContextProvider');

    return context;
};
