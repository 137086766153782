import { LinearLoader } from "../../../../components/linear-loader/LinearLoader";
import { useEffect } from "react";
import { MarketService } from "../../../../services/marketService";
import { getMarketplaceOfUser } from "../../../../api";
import { useSnackbar } from "notistack";
import { getMarketPlaceSellStats } from "../../../../utils/sell-stats";
import { useMarketplaceContext } from "../../../../context-state/MarketplaceContext";

export const MarketSaleStatsGuard = ({ children }) => {

    const { enqueueSnackbar } = useSnackbar();
    const { marketplaceSaleStats, setMarketPlaceSaleStats, setMarketPlaceGeneraleSaleStats, marketplace } = useMarketplaceContext();

    const getMarketPlaceSaleStats = async () => {
        try {
            const marketPlaceSalesData = await MarketService.getMarketPlaceSaleStatsData(marketplace._id)
            const { res, generalStats } = getMarketPlaceSellStats(marketPlaceSalesData, marketplace)
            setMarketPlaceSaleStats(res)
            setMarketPlaceGeneraleSaleStats(generalStats)
        } catch (e) {
            console.log(e);
            enqueueSnackbar(e.message, { variant: 'error' })
            setMarketPlaceSaleStats([])
        }
    }


    useEffect(() => {
        // TODO - add if marketplaceSaleStats
        getMarketPlaceSaleStats()
    }, []);

    return (
        marketplaceSaleStats ? children : <LinearLoader />
    )
}
