export const tzToCountry = (tz, defaultCountryCode) => {
    const { ct } = window; // https://www.npmjs.com/package/countries-and-timezones
    const tzCountryData = ct.getTimezone(tz);
    const countryCode = tzCountryData?.countries?.[0] || defaultCountryCode;
    return countryCode
}

/**
 * extract the country code from the browser timezone
 * @param {*} defaultCountryCode 
 * @returns 
 */
export const getCountryCodeFromBrowserTimeZone = (defaultCountryCode) => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!tz) {
        return defaultCountryCode;
    }
    return tzToCountry(tz, defaultCountryCode);
}
