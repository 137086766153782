import { useAuthContext } from "../../../context-state/AuthContext";
import { useDashboardContext } from "../../../context-state/DashboardContext";
import { EventService } from "../../../services/eventService";
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useSnackbar } from "../../../components/minimal/snackbar";
import { useLocales } from "../../../locales";
import { LinearLoader } from "../../../components/linear-loader/LinearLoader";

// Fetch all relevant data for a single event and prepare the context state
export const EventGuard = ({ children }) => {

    const { account } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();
    const { translate } = useLocales()
    const { eventContext, userEventContext, eventOrderContext, ticketContext, couponContext, utmContext, linkContext } = useDashboardContext();
    const { getEvent } = eventContext
    const { updateManyEventOrders, updateEventToEventOrdersRelation, getEventOrdersOfEvent } = eventOrderContext
    const { updateManyUserEvents, updateEventToUserEventRelation } = userEventContext
    const { updateManyCoupons, updateEventToCouponsRelation } = couponContext
    const { getTicketsOfEvent, updateManyTickets, updateEventToTicketsRelation } = ticketContext
    const { updateManyUtms, updateEventToUtmsRelation } = utmContext
    const { updateManyLinks, updateEventToLinksRelation } = linkContext

    const { eventId } = useParams();
    const event = getEvent(eventId)
    const eventTickets = getTicketsOfEvent(eventId)
    const eventOrders = getEventOrdersOfEvent(eventId)

    const getAllUserEventRelevantData = async () => {
        try {
            const { userEvents, eventOrders, tickets, coupons, utms, links } = await EventService.getEventDashboardData({ accountId: account._id, eventId: event._id })
            // Tickets
            const normalizedTickets = tickets || []
            updateManyTickets(normalizedTickets)
            updateEventToTicketsRelation(event._id, normalizedTickets)
            // UserEvent
            const normalizedUserEvents = userEvents || []
            updateManyUserEvents(normalizedUserEvents)
            updateEventToUserEventRelation(event._id, normalizedUserEvents)
            // EventOrders
            const normalizedEventOrders = eventOrders || []
            updateManyEventOrders(normalizedEventOrders)
            updateEventToEventOrdersRelation(event._id, normalizedEventOrders)
            // Coupons
            const normalizedCoupons = coupons || []
            updateManyCoupons(normalizedCoupons)
            updateEventToCouponsRelation(event._id, normalizedCoupons)
            // Utms
            const normalizedUtms = utms || []
            updateManyUtms(normalizedUtms)
            updateEventToUtmsRelation(event._id, normalizedUtms)
            // Links
            const normalizedLinks = links || []
            updateManyLinks(normalizedLinks)
            updateEventToLinksRelation(event._id, normalizedLinks)


        } catch (e) {
            console.log(e);
            enqueueSnackbar(translate('general_error'), { variant: 'error' });
        }
    }

    useEffect(() => {
        if ((!eventTickets || !eventOrders) && event) {
            getAllUserEventRelevantData()
        }
    }, [event, eventTickets, eventOrders])

    const isAllRelevantEventDataFetched = (event && eventTickets && eventOrders)

    return (
        isAllRelevantEventDataFetched ? <Outlet /> : <LinearLoader />
    )
}
