import { useLocation, Outlet, useNavigate } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
//
import Footer from './Footer';
import Header from './Header';
import { useEffect } from "react";
import { isRadical } from "../../utils/market.js";

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isHome = pathname === '/';

    useEffect(() => {
        // Why we do this? Radical don't want landing page.
        if (isRadical()) {
            navigate('/auth/login')
        }
    }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Header />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(!isHome && {
            pt: { xs: 8, md: 11 },
          }),
        }}
      >
        <Outlet />
      </Box>

      {/*<Footer />*/}
    </Box>
  );
}
