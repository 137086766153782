import { useSnackbar } from "../../../components/minimal/snackbar";
import { useLocales } from "../../../locales";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Box, Grid, Stack } from "@mui/material";
import FormProvider, { RHFTextField } from "../../../components/minimal/hook-form";
import { LoadingButton } from "@mui/lab";
import { m } from "framer-motion";
import React from "react";


export const SmsCampaignProperties = ({onSuccess, smsCampaignContent}) => {

    const {enqueueSnackbar} = useSnackbar();
    const {translate} = useLocales()

    const SmsCampaignSchema = Yup.object().shape({
        smsCampaignName: Yup.string().trim().required(translate('dashboard.events.required_field')),
        smsCampaignContent: Yup.string().trim().required(translate('dashboard.events.required_field')),
    });

    const defaultValues = {
        smsCampaignName: smsCampaignContent.name,
        smsCampaignContent: smsCampaignContent.content // At least one phone number is required initially
    }

    const methods = useForm({resolver: yupResolver(SmsCampaignSchema), defaultValues});
    const {handleSubmit, formState: {isSubmitting}} = methods;

    const onSubmit = (data) => {
        try {
            const {smsCampaignContent, smsCampaignName} = data;

            if (smsCampaignContent.length >= 1000) {
                enqueueSnackbar(translate('dashboard.sms_campaign.sms_alert_chars_must_be_less_then_1000_characters'), {variant: 'error'});
                return;
            }
            onSuccess(smsCampaignContent, smsCampaignName);
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Unable to create sms message!', { variant: 'error' });
        }
    };

    return (
        <m.div
            initial={{opacity: 0, y: 20}} // Initial state
            animate={{opacity: 1, y: 0}}   // Animation state
            exit={{opacity: 0, y: -20}}    // Exit state
            transition={{duration: 0.3}}   // Transition duration
        >
            <FormProvider width="100%" methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box>
                    <RHFTextField name="smsCampaignName" label="שם הקמפיין"/>
                </Box>
                <Box sx={{paddingTop: 2}}>
                    <RHFTextField name="smsCampaignContent" label="תוכן הסמס (עד 1000 תווים)" fullWidth multiline
                                  rows={4}/>
                </Box>
                <Stack alignItems="flex-end" sx={{my: 3}}>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        הוספה
                    </LoadingButton>
                </Stack>
            </FormProvider>
        </m.div>
    )
}
