import { isMyKind, isEasyTix, isHormesis, isRadical, isZoa, isMinkovsky } from "../utils/market";

export const FaviconHandler = () => {

    const head = document.getElementsByTagName('head')[0];
    const appleIcon = head.querySelector('[aria-roledescription="icon-apple"]')
    const largeIcon = head.querySelector('[aria-roledescription="icon-l"]')
    const smallIcon = head.querySelector('[aria-roledescription="icon-s"]')

    if (isMyKind()) {
        if (appleIcon) {
            appleIcon.href = "/favicon_my_kind/apple-touch-icon.png"
        }
        if (largeIcon) {
            largeIcon.href = "/favicon_my_kind/favicon-32x32.png"
        }
        if (smallIcon) {
            smallIcon.href = "/favicon_my_kind/favicon-16x16.png"
        }
    }
    if (isEasyTix()) {
        if (appleIcon) {
            appleIcon.href = "/favicon_easytix/apple-touch-icon.png"
        }
        if (largeIcon) {
            largeIcon.href = "/favicon_easytix/favicon-32x32.png"
        }
        if (smallIcon) {
            smallIcon.href = "/favicon_easytix/favicon-16x16.png"
        }
    }

    if (isHormesis()) {
        if (appleIcon) {
            appleIcon.href = "/favicon_hormesis/apple-touch-icon.png"
        }
        if (largeIcon) {
            largeIcon.href = "/favicon_hormesis/favicon-32x32.png"
        }
        if (smallIcon) {
            smallIcon.href = "/favicon_hormesis/favicon-16x16.png"
        }
    }

    if (isRadical()) {
        if (appleIcon) {
            appleIcon.href = "https://radical.org.il/wp-content/uploads/2021/06/fav.png"
        }
        if (largeIcon) {
            largeIcon.href = "https://radical.org.il/wp-content/uploads/2021/06/fav.png"
        }
        if (smallIcon) {
            smallIcon.href = "https://radical.org.il/wp-content/uploads/2021/06/fav.png"
        }
    }

    if (isZoa()) {
        if (appleIcon) {
            appleIcon.href = "/favicon_zoa/apple-touch-icon.png"
        }
        if (largeIcon) {
            largeIcon.href = "/favicon_zoa/favicon-32x32.png"
        }
        if (smallIcon) {
            smallIcon.href = "/favicon_zoa/favicon-16x16.png"
        }
    }

    if (isMinkovsky()) {
        if (appleIcon) {
            appleIcon.href = "/favicon_minkovsky/apple-touch-icon.png"
        }
        if (largeIcon) {
            largeIcon.href = "/favicon_minkovsky/favicon-32x32.png"
        }
        if (smallIcon) {
            smallIcon.href = "/favicon_minkovsky/favicon-16x16.png"
        }
    }

    return null
}
