import { LinearLoader } from "../../../../components/linear-loader/LinearLoader.jsx";
import { useSeasonTicketContext } from "../../../../context-state/SeasonTicketContext.jsx";
import { useEffect } from "react";
import { getSeasonTicketsOfSeller } from "../../../../api/index.js";
import { useAuthContext } from "../../../../context-state/AuthContext.jsx";

export const SeasonTicketGuard = ({ children }) => {

    const { getPrimaryAccount } = useAuthContext()
    const sellerAccount = getPrimaryAccount()
    const { updateManySeasonTicket, updateSellerAccountToSeasonTicketRelation, getSeasonTicketOfSellerAccount } = useSeasonTicketContext()

    const seasonTickets = getSeasonTicketOfSellerAccount(sellerAccount._id)

    const fetchData = async () => {
        try {
            const { data } = await getSeasonTicketsOfSeller({ marketplaceId: sellerAccount.marketPlace._id, sellerId: sellerAccount._id })
            updateManySeasonTicket(data)
            updateSellerAccountToSeasonTicketRelation(sellerAccount._id, data)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (!seasonTickets) {
            fetchData()
        }
    }, [])

    return (
        seasonTickets ? children : <LinearLoader />
    )
}
