import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import {
    Box,
    Step,
    Stack,
    Stepper,
    StepLabel,
    StepConnector as MUIStepConnector,
} from '@mui/material';
import Iconify from "./minimal/iconify";
// components

// ----------------------------------------------------------------------

const StepConnector = styled(MUIStepConnector)(({ theme }) => ({
    top: 10,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
    '& .MuiStepConnector-line': {
        borderTopWidth: 2,
        borderColor: theme.palette.divider,
    },
    '&.Mui-active, &.Mui-completed': {
        '& .MuiStepConnector-line': {
            borderColor: theme.palette.primary.main,
        },
    },
}));

// ----------------------------------------------------------------------

Steps.propTypes = {
    sx: PropTypes.object,
    activeStep: PropTypes.number,
    steps: PropTypes.arrayOf(PropTypes.string),
};

export function Steps({ steps, activeStep, sx, ...other }) {
    return (
        <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<StepConnector />}
            sx={{ mb: 5, ...sx }}
            {...other}
        >
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel
                        StepIconComponent={StepIcon}
                        sx={{
                            '& .MuiStepLabel-label': {
                                typography: 'subtitle2',
                            },
                        }}
                    >
                        {label}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
}

// ----------------------------------------------------------------------

StepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
};

function StepIcon({ active, completed }) {
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                width: 24,
                height: 24,
                color: 'text.disabled',
                ...(active && {
                    color: 'primary.main',
                }),
            }}
        >
            {completed ? (
                <Iconify icon="eva:checkmark-fill" sx={{ color: 'primary.main' }} />
            ) : (
                <Box
                    sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        backgroundColor: 'currentColor',
                    }}
                />
            )}
        </Stack>
    );
}
