import { Autocomplete, Card, Stack, TextField, Typography } from "@mui/material";
import { useEndUsersContext } from "../../../context-state/EndUsersContext.jsx";
import { useAuthContext } from "../../../context-state/AuthContext.jsx";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useSnackbar } from "notistack";
import * as API from "../../../api/index.js";

const israeliNumberRegex = /^(?:\+972|0|972)5\d{7,10}$/;

export const SmsSegmentOption = ({ setSmsCampaignPhoneNumbersSegment }) => {

    const { segmentsMap } = useEndUsersContext()
    const { getPrimaryAccount } = useAuthContext()
    const sellerAccount = getPrimaryAccount()
    const { enqueueSnackbar } = useSnackbar()
    const [chosenSegments, setChosenSegments] = useState([])
    const [isLoading, setIsLoading] = useState(false)


    let segmentsToPresent = []

    if (segmentsMap) {
        segmentsToPresent = Object.values(segmentsMap)
    }

    const getEndUsersSegments = async () => {
        try {
            setIsLoading(true)
            const allEndUserDataResponse = await Promise.all(
                chosenSegments.map(segment => API.getEndUsersOfSegment({ marketplaceId: sellerAccount.marketPlace._id, accountId: sellerAccount._id, segmentId: segment._id, shouldPopulateEndUser: true }).then(res => res.data))
            )

            const allValidPhoneNumbers = allEndUserDataResponse
                .flat()
                .filter((segmentData) => segmentData?.endUser?.phone)
                .filter(({ endUser }) => endUser.phone.match(israeliNumberRegex))
                .map(({ endUser }) => endUser.phone)

            if (!allValidPhoneNumbers.length) {
                enqueueSnackbar("לא קיימים מספרי טלפון לסגמנטים אלו", { variant: 'warning' })
                return
            }

            setSmsCampaignPhoneNumbersSegment(allValidPhoneNumbers)

        } catch (e) {
            console.log(e)
            enqueueSnackbar(e.message, { variant: 'error' })
        } finally {
            setIsLoading(false)
        }
    }


    return (
        <Card sx={{ p: 3, mb: 2 }}>
            <Typography variant="h6" sx={{color: 'text.secondary'}}>
                בחירת מספרי טלפון מסגמנט
            </Typography>


            <Stack sx={{ gap: 2, mt: 2 }}>
                <Autocomplete
                    multiple
                    value={chosenSegments}
                    getOptionLabel={(segment) => segment.name}
                    options={segmentsToPresent}
                    onChange={(event, newValue) => setChosenSegments(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="בחירת סגמנטים"
                        />
                    )}
                />
                <LoadingButton loading={isLoading} disabled={!chosenSegments.length} onClick={getEndUsersSegments} variant="contained">
                    אישור
                </LoadingButton>
            </Stack>

            {/*<Select*/}
            {/*    multiple*/}
            {/*>*/}
            {/*    {*/}
            {/*        segmentsToPresent.map((segment, index) => {*/}
            {/*            return (*/}
            {/*                <MenuItem key={segment._id} value={segment}>{segment.name}</MenuItem>*/}
            {/*            )*/}
            {/*        })*/}
            {/*    }*/}

            {/*</Select>*/}
        </Card>
    )
}
