import { LinearLoader } from "../../../components/linear-loader/LinearLoader.jsx";
import { useEffect } from "react";
import { useSellerPageContext } from "../../../context-state/SellerPageContext.jsx";
import { useNavigate } from "react-router-dom";
import { ErrorService } from "../../../services/errorService.js";
import * as API from "../../../api/index.js";
import { isFutureEvent } from "../../../utils/sort.js";

export const SellerPublicPageGuard = ({ children }) => {

    const navigate = useNavigate();
    const navigateHome = () => navigate('/', { replace: true });
    const { accountContext, eventContext } = useSellerPageContext()
    const { account, setAccount } = accountContext
    const { setEvents } = eventContext

    const getSellerPageData = async () => {
        try {
            const [, ,accountHandler] = window.location.pathname.split('/')
            const accountResponse = await API.getAccountIdByHandler(accountHandler)
            const eventsResponse = await API.getPublicEventsOfAccount(accountResponse.data._id)

            const futureEventsOnly = eventsResponse.data ? eventsResponse.data.filter(isFutureEvent) : []

            setAccount(accountResponse.data)
            setEvents(futureEventsOnly)
        } catch (e) {
            console.log(e);
            ErrorService.handleError(e)
            navigateHome()
        }

    }

    useEffect(() => {
        getSellerPageData()
    }, []);

    return (
        account ? children : <LinearLoader />
    )
}
