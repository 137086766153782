import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../context-state/AuthContext';
// components
import { CustomAvatar } from '../../../components/minimal/custom-avatar';
import { useSnackbar } from '../../../components/minimal/snackbar';
import MenuPopover from '../../../components/minimal/menu-popover';
import { IconButtonAnimate } from '../../../components/minimal/animate';
import { getUserAvatarPath } from "../../../utils/userAvatar";
import { useLocales } from "../../../locales";
import { APP_VERSION } from "../../../config-global";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AccountPopover() {

  const navigate = useNavigate();
  const { translate } = useLocales()

  const OPTIONS = [
    {
      label: translate('header.home'),
      linkTo: '/',
    },
    // {
    //   label: 'Profile',
    //   linkTo: PATH_DASHBOARD.user.profile,
    // },
    // {
    //   label: 'Settings',
    //   linkTo: PATH_DASHBOARD.user.account,
    // },
  ];


  const { user, logout } = useAuthContext();
  const userName = `${user?.lname} ${user?.fname}`
  const userImage = user?.imageUrl ? user?.imageUrl : getUserAvatarPath()
  const { enqueueSnackbar } = useSnackbar();


  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      logout();
      handleClosePopover();
      window.location.href = window.location.origin + '/auth/login'
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar src={userImage} alt={userName} name={userName} />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {translate('header.logout')}
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Typography component="div" variant="caption" sx={{ px: 2, mb: 1 }}>
          v{APP_VERSION}
        </Typography>

      </MenuPopover>
    </>
  );
}
