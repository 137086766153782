import { FieldsTypes, GeneralFieldsName } from "../consts";
import { fDate } from "./minimal/formatTime.js";
import NimiTermsOfService from '../assets/docs/nimi-terms-of-services.pdf'
import NimiPrivacyPolicy from '../assets/docs/nimi-privacy-policy.pdf'
import MyKindPrivacyPolicy from '../assets/docs/mykind-privacy-policy.pdf'
import MyKindTermsOfService from '../assets/docs/mykind-terms-of-services.pdf'
import * as Yup from "yup";

export const extractId = (entity) => entity._id

export const eventInputDataToFields = (eventInputData = []) => {
    const fields = { custom_questions: [] }
    for (const field of eventInputData) {
        switch (field.name) {
            case GeneralFieldsName.B_DAY:
                fields.birthdayField = field
                break
            case GeneralFieldsName.L_NAME:
                fields.lnameField = field
                break
            case GeneralFieldsName.F_NAME:
                fields.fnameField = field
                break
            case GeneralFieldsName.EMAIL:
                fields.emailField = field
                break
            case GeneralFieldsName.PHONE:
                fields.phoneField = field
                break
            case GeneralFieldsName.GENDER:
                fields.genderField = field
                break
            case GeneralFieldsName.SOCIAL_LINK:
                fields.socialLinkField = field
                break
            case GeneralFieldsName.EMAIL_MARKETING:
                fields.emailMarketingField = field
                break
            default:
                if (field.name?.startsWith('custom_q_')) {
                    fields.custom_questions.push({
                        custom_name: field.name,
                        custom_placeholder: field.placeholder,
                        custom_required: field.required,
                        custom_type: field.type
                    })
                } else {
                    console.log("what is this field?");
                    console.log(field);
                }
        }
    }
    return fields
}

export const buildUserEventSchemaByEventInputData = (formFields, ignoreCustomQuestions = false) => {
    const ticketSchema = {}
    const { birthdayField, lnameField, fnameField, emailField, phoneField, genderField, socialLinkField, custom_questions } = formFields;

    if (birthdayField?.required) {
        ticketSchema.birth_date = Yup.date().required() // .min .max('2019-08-01')
    } else if (!birthdayField?.isHidden) {
        ticketSchema.birth_date = Yup.date()
    }

    if (lnameField.required) {
        ticketSchema.lname = Yup.string().trim().required()
    } else if (!lnameField.isHidden) {
        ticketSchema.lname = Yup.string().trim()
    }

    if (fnameField.required) {
        ticketSchema.fname = Yup.string().trim().required()
    } else if (!fnameField.isHidden) {
        ticketSchema.fname = Yup.string().trim()
    }

    // if (emailField.required) {
    ticketSchema.email = Yup.string().trim().lowercase().required()
    // } else if (!emailField.isHidden) {
    //     ticketSchema.email = Yup.string().trim().lowercase()
    // }

    // if (phoneField.required) {
    ticketSchema.phone = Yup.string().trim().required()
    // } else if (!phoneField.isHidden) {
    //     ticketSchema.phone = Yup.string().trim()
    // }

    if (genderField.required) {
        ticketSchema.gender = Yup.string().required()
    } else if (!genderField.isHidden) {
        ticketSchema.gender = Yup.string()
    }

    // if (socialLinkField.required) {
    //     ticketSchema.social_link = Yup.string().trim().required()
    // } else if (!socialLinkField.isHidden) {
    //     ticketSchema.social_link = Yup.string().trim()
    // }

    if (custom_questions.length && !ignoreCustomQuestions) {
        for (const customQ of custom_questions) {
            switch (customQ.custom_type) {
                case FieldsTypes.STRING:
                    ticketSchema[customQ.custom_name] = customQ.custom_required ? Yup.string().trim().required() : Yup.string().trim()
                    break
                case FieldsTypes.DATE_NO_TIME:
                    ticketSchema[customQ.custom_name] = customQ.custom_required ? Yup.date().required() : Yup.date()
                    break
                case FieldsTypes.YES_OR_NO:
                    ticketSchema[customQ.custom_name] = customQ.custom_required ? Yup.boolean().required() : Yup.boolean()
                    break
            }

        }
    }

    return ticketSchema
}

export const extractCustomQuestionsFromEventInputData = (eventInputData) => {
    const results = []
    for (const inputData of eventInputData) {
        if (inputData.name.startsWith('custom_q')) {
            results.push(inputData)
        }
    }
    return results
}

export const transformCustomQuestionValueByType = (customQuestion, value) => {
    if (!value) {
        return "-"
    }
    switch (customQuestion.type) {
        case FieldsTypes.DATE_NO_TIME:
            return fDate(value)
        case FieldsTypes.YES_OR_NO:
            return value === "1" ? "כן" : "לא"
        case FieldsTypes.STRING:
            return value
    }
}

// Prepare number for whatsApp format
export const normalizePhoneNumber = (number) => {
    if (number.startsWith('0') || number.startsWith('+')) {
        return number.slice(1, 1) + number.slice(1, number.length)
    }
}

// What is it? no idea why but i need to convert each hebrew letter like that or else it will be blank on the execl.
export const convertToSafeHebrew = (string) => {
    const isEnglish = /^[a-zA-Z\s]+$/.test(string)
    if (isEnglish) {
        return string
    }

    const hebrewLetters = {
        "א": "א",
        "ב": "ב",
        "ג": "ג",
        "ד": "ד",
        "ה": "ה",
        "ו": "ו",
        "ז": "ז",
        "ח": "ח",
        "ט": "ט",
        "י": "י",
        "כ": "כ",
        "ל": "ל",
        "מ": "מ",
        "נ": "נ",
        "ס": "ס",
        "ע": "ע",
        "פ": "פ",
        "צ": "צ",
        "ק": "ק",
        "ר": "ר",
        "ש": "ש",
        "ת": "ת",
        "ך": "ך",
        "ם": "ם",
        "ן": "ן",
        "ף": "ף",
        "ץ": "ץ"
    };
    return string
        .split("")
        .map(char => hebrewLetters[char])
        .join("")
}

export const getRelevantPaymentFormError = (e, translate) => {
    const { EXPIRATION, NUMBER, CVC, NAME_FIRST, NAME_LAST } = window.PayMe.fields

    let errorToRender
    // setting a default error message in case we don't have a specific one to show
    errorToRender = e?.payload?.status_error_details || e?.message
    if (e?.message) {
        if (e.message === "Invalid phone number") {
            errorToRender = translate('event_page.payment_invalid_phone_number')
            return errorToRender
        }
    }
    // Mean we had an error with the 'PayMe'
    if (e?.type === 'tokenize-error') {
        // This is errors from PayMe api (after form submitted)
        if (e.payload) {
            if (e.payload.status_error_details === "Invalid credit card expiry") {
                errorToRender = translate('event_page.invalid_card_expiry')
                return errorToRender
            }
            if (e.payload.status_error_details === "Incorrect card number") {
                errorToRender = translate('event_page.invalid_card_number')
                return errorToRender
            }
            if (e.payload.status_error_details === "Invalid credit card CVV") {
                errorToRender = translate('event_page.invalid_card_cvv')
                return errorToRender
            }
            if (e.payload.status_error_details && e.payload.status_error_details.toLowerCase().includes("refusal by credit company")) {
                errorToRender = translate('event_page.refusal_payment')
                return errorToRender
            }
            if (e.payload.status_error_details) {
                errorToRender = e.payload.status_error_details
                return errorToRender
            }
        }
        // This is errors from PayMe sdk (before form submitted)
        if (e.errors) {
            if (e.errors[EXPIRATION]) {
                errorToRender = translate('event_page.missing_fields')
            }
            if (e.errors[NUMBER]) {
                errorToRender = translate('event_page.missing_fields')
            }
            if (e.errors[CVC]) {
                errorToRender = translate('event_page.missing_fields')
            }
            if (e.errors[NAME_FIRST]) {
                errorToRender = translate('event_page.missing_fields')
            }
            if (e.errors[NAME_LAST]) {
                errorToRender = translate('event_page.missing_fields')
            }
        }
    }
    return errorToRender
}

export const getRelevantHrcError = (e) => {
    let message = e.message ? e.message.toLowerCase() : ""
    if (message) {
        if (message.includes("already used for this event")) {
            return "מנוי כבר שומש"
        }
        if (message.includes("season ticket not active or limit is reached")) {
            return "מנוי נמצא אך לא פעיל או עבר את המכסה"
        }
        if (message.includes("end user season ticket limit reached for this event")) {
            return " לא נותרו כניסות למימוש באירוע זה "
        }
        if (message.includes("end user season ticket limit reached")) {
            return " לא נותרו כניסות למימוש "
        }
    }
    return 'מנוי לא נמצא'
}
