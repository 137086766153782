import { Box, Button, Card, Stack, Typography } from "@mui/material";
import { SmsCampaignFromExcelForm } from "./SmsCampaignFromExcelForm.jsx";
import { useLocales } from "../../../locales/index.js";
import { SmsCampaignManuallyForm } from "./SmsCampaignManuallyForm.jsx";
import { m } from "framer-motion";
import { useSnackbar } from "../../../components/minimal/snackbar/index.jsx";
import { SmsSegmentOption } from "./SmsSegmentOption.jsx";


export const SmsCampaignRecipients = ({ onSuccess, smsCampaignPhoneNumbersManually, setSmsCampaignPhoneNumbersManually, smsCampaignPhoneNumbersExcel, setSmsCampaignPhoneNumbersExcel, excelFile, setExcelFile, smsCampaignPhoneNumbersSegment, setSmsCampaignPhoneNumbersSegment }) => {

    const { translate } = useLocales()
    const { enqueueSnackbar } = useSnackbar()

    const onSubmitManual = () => {
        try {
            onSuccess();
        } catch (error) {
            console.error(error);
            enqueueSnackbar(translate('dashboard.sms_campaign.sms_unable_to_add_phone_numbers'), { variant: 'error' });
        }
    }

    return (
        <>
            {(smsCampaignPhoneNumbersManually.length > 0 || smsCampaignPhoneNumbersExcel.length > 0 || smsCampaignPhoneNumbersSegment.length > 0) &&
                <m.div
                    initial={{opacity: 0, y: 20}} // Initial state
                    animate={{opacity: 1, y: 0}}   // Animation state
                    exit={{opacity: 0, y: -20}}    // Exit state
                    transition={{duration: 0.3}}   // Transition duration
                >
                    <Card sx={{ p: 3, marginBottom: 2 }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            p: 2,
                            flexWrap: 'wrap'
                        }}>
                            {smsCampaignPhoneNumbersManually.length > 0 &&
                                (<Box>
                                    <Typography variant="h6" sx={{color: 'text.secondary'}}>
                                        {translate('dashboard.sms_campaign.upload_phones_numbers_manually')}
                                    </Typography>
                                    {smsCampaignPhoneNumbersManually.map((phone, index) => {
                                        return (<div key={index}>{phone}</div>)
                                    })}
                                </Box>)
                            }

                               {
                                   smsCampaignPhoneNumbersExcel.length > 0 &&
                                   <Stack>
                                       <Box>
                                           <Typography variant="h6" sx={{color: 'text.secondary'}}>
                                               {translate('dashboard.sms_campaign.upload_phone_numbers_from_excel_file')}
                                           </Typography>
                                           <Stack sx={{ overflowY: "scroll", maxHeight: "200px", my: 2 }}>
                                               {
                                                   smsCampaignPhoneNumbersExcel.map((phone, index) => {
                                                       return (
                                                           <Stack direction="row" sx={{ alignItems: "center" }} key={index}>
                                                               <Box sx={{ mr: 1, fontWeight: "bold" }}>{index + 1}.</Box>
                                                               {phone}

                                                           </Stack>
                                                       )
                                                   })
                                               }
                                           </Stack>
                                           {
                                               <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 1 }}>
                                                   סך הכל - {smsCampaignPhoneNumbersExcel.length} מספרים
                                               </Typography>
                                           }
                                       </Box>
                                   </Stack>
                               }


                            <Stack>
                                {
                                    smsCampaignPhoneNumbersSegment.length > 0 &&
                                    <Box>
                                        <Typography variant="h6" sx={{color: 'text.secondary'}}>
                                            מספרי טלפון מסגמנטים
                                        </Typography>
                                        <Stack sx={{ overflowY: "scroll", maxHeight: "200px", my: 2 }}>
                                            {
                                                smsCampaignPhoneNumbersSegment.map((phone, index) => {
                                                    return (
                                                        <Stack direction="row" sx={{ alignItems: "center" }} key={index}>
                                                            <Box sx={{ mr: 1, fontWeight: "bold" }}>{index + 1}.</Box>
                                                            {phone}

                                                        </Stack>
                                                    )
                                                })
                                            }
                                        </Stack>
                                        {
                                            <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 1 }}>
                                                סך הכל - {smsCampaignPhoneNumbersSegment.length} מספרים
                                            </Typography>
                                        }
                                    </Box>
                                }
                            </Stack>

                            <Button variant="contained" sx={{ width: "100%", mt: 2 }} onClick={onSubmitManual}>
                                המשך
                            </Button>
                        </Box>
                    </Card>
                </m.div>
            }
            <m.div
                initial={{opacity: 0, y: 20}} // Initial state
                animate={{opacity: 1, y: 0}}   // Animation state
                exit={{opacity: 0, y: -20}}    // Exit state
                transition={{duration: 0.3}}   // Transition duration
            >
                <SmsSegmentOption
                    smsCampaignPhoneNumbersSegment={smsCampaignPhoneNumbersSegment}
                    setSmsCampaignPhoneNumbersSegment={setSmsCampaignPhoneNumbersSegment}
                />
                <SmsCampaignManuallyForm
                    setSmsCampaignPhoneNumbersManually={setSmsCampaignPhoneNumbersManually}
                    smsCampaignPhoneNumbersManually={smsCampaignPhoneNumbersManually}
                />
                <SmsCampaignFromExcelForm
                    excelFile={excelFile}
                    setExcelFile={setExcelFile}
                    setSmsCampaignPhoneNumbersExcel={setSmsCampaignPhoneNumbersExcel}
                    smsCampaignPhoneNumbersExcel={smsCampaignPhoneNumbersExcel}
                />
            </m.div>
        </>
    )
}
