import { useSnackbar } from "notistack";
import { useDashboardContext } from "../../../context-state/DashboardContext";
import useCopyToClipboard from "../../../hooks/minimal/useCopyToClipboard";
import { useState } from "react";
import { useLocales } from "../../../locales";
import { styled, useTheme } from "@mui/material/styles";
import {
    Checkbox,
    Divider,
    IconButton,
    MenuItem,
    Stack,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import Label from "../../../components/minimal/label";
import Iconify from "../../../components/minimal/iconify";
import { fDateTime } from "../../../utils/minimal/formatTime";
import MenuPopover from "../../../components/minimal/menu-popover";
import { LinksUtm } from "../../../consts";
import { useEventLink } from "../../../hooks/useEventLink";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { RoleBasedComponentGuard, ROLES_LABELS } from "../../common/guards/RoleGuard.jsx";

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});


export const LinkTableRow = ({ row, selected, onSelectRow, onEditRow, onDeleteRow, utmData = { clicks: 0, visitCountUnique: 0, visitCount: 0 } }) => {

    const { name, utm, createdAt, tickets, is_enabled, description, sold_count, refund_count, cancel_count, pending_count } = row;

    const pending = pending_count - (cancel_count + sold_count + refund_count)
    const actualPending = (pending < 0 || isNaN(pending)) ? 0 : pending


    const { enqueueSnackbar } = useSnackbar();
    const { ticketContext } = useDashboardContext()
    const { getTicket } = ticketContext
    const { copy } = useCopyToClipboard();
    const [openPopover, setOpenPopover] = useState(null);

    const isPrimary = utm === LinksUtm.PRIMARY
    const eventBaseUrl = useEventLink()
    const fullLink = isPrimary ? eventBaseUrl : `${eventBaseUrl}?utm=${utm}`


    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };

    const handleClick = () => {}
    const { translate } = useLocales()

    const handleCopy = () => {
        enqueueSnackbar(translate('dashboard.events.copied'));
        copy(fullLink);
    };

    const goToLink = () => {
        window.open(fullLink, "_blank")
    }

    const { palette } = useTheme()

    return (
        <>
            <TableRow
                sx={{
                    borderRadius: 1,
                    '& .MuiTableCell-root': {
                        bgcolor: 'background.default',
                    }
                }}
            >
                <TableCell
                    padding="checkbox"
                    sx={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}
                >
                    {
                        !isPrimary ?
                            <Checkbox
                                checked={selected}
                                onClick={onSelectRow}
                            /> : null
                    }

                </TableCell>

                <TableCell>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography noWrap variant="inherit" sx={{ maxWidth: 360 }}>
                            {name}
                        </Typography>
                    </Stack>
                </TableCell>

                <TableCell>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography noWrap variant="inherit" sx={{ maxWidth: 360 }}>
                            {description}
                        </Typography>
                    </Stack>
                </TableCell>

                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    <NoMaxWidthTooltip placement="top" title={fullLink}>
                        <Label onClick={handleCopy} sx={{ py: 2, cursor: "pointer" }} color="info" variant="soft">
                            <Iconify icon="eva:link-2-outline"/>
                        </Label>
                    </NoMaxWidthTooltip>
                    <Label onClick={goToLink} sx={{ py: 2, cursor: "pointer", ml: 1 }} color="info" variant="soft">
                        <Iconify icon="eva:external-link-outline"/>
                    </Label>
                </TableCell>

                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {fDateTime(createdAt)}
                </TableCell>

                <TableCell>
                    <Stack>
                        {
                            isPrimary ?
                                <Typography variant="inherit" sx={{ maxWidth: 360 }}>
                                    *
                                </Typography> :
                            tickets.map((ticketId, i) => {
                                const ticket = getTicket(ticketId)
                                if (!ticket){
                                    console.log('no ticket with id', ticketId);
                                    return null
                                }
                                return (
                                    <Typography key={i} noWrap variant="inherit" sx={{ maxWidth: 360 }}>
                                        - {ticket.name}
                                    </Typography>
                                )
                            })
                        }
                    </Stack>
                </TableCell>

                <TableCell
                    onClick={handleClick}
                    sx={{ whiteSpace: 'nowrap' }}
                >
                    <Label sx={{ py: 2, textTransform: "unset" }} color={is_enabled ? 'success' : 'warning'}>
                        {is_enabled ? translate('dashboard.events.active') : translate('dashboard.events.inactive')}
                    </Label>
                </TableCell>

                <TableCell onClick={handleClick}>
                    { utmData.visitCount }
                </TableCell>

                <TableCell onClick={handleClick}>
                    { utmData.visitCountUnique }
                </TableCell>

                <TableCell>
                    { sold_count }
                </TableCell>

                <TableCell>
                    { refund_count }
                </TableCell>

                <TableCell>
                    { cancel_count }
                </TableCell>

                <TableCell>
                    { actualPending }
                </TableCell>

                <TableCell
                    align="right"
                    sx={{
                        whiteSpace: 'nowrap',
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                    }}
                >
                    <RoleBasedComponentGuard minimumRoleAllowedAccess={ROLES_LABELS.EDITOR}>
                        <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
                            <Iconify icon="eva:more-vertical-fill" />
                        </IconButton>
                    </RoleBasedComponentGuard>
                </TableCell>

            </TableRow>

            <MenuPopover
                open={openPopover}
                onClose={handleClosePopover}
                arrow="right-top"
                sx={{ width: 160 }}
            >
                <MenuItem
                    onClick={() => {
                        handleClosePopover();
                        handleCopy();
                    }}
                >
                    <Iconify icon="eva:link-2-fill" />
                    {`${translate('dashboard.events.copy')} ${translate('dashboard.events.link')}`}
                </MenuItem>
                {
                    isPrimary ? null :
                        <MenuItem
                            onClick={() => {
                                handleClosePopover();
                                onEditRow()
                            }}
                        >
                            <Iconify icon="eva:edit-outline" />
                            {translate('dashboard.events.edit')}
                        </MenuItem>

                }


                {
                    isPrimary ? null :
                        <>
                            <Divider sx={{ borderStyle: 'dashed' }} />
                            <MenuItem
                                onClick={() => {
                                    handleClosePopover();
                                    onDeleteRow()
                                }}
                                sx={{ color: 'error.main' }}
                            >
                                <Iconify icon="eva:trash-2-outline" />
                                {translate('dashboard.events.delete')}
                            </MenuItem>
                        </>
                }


            </MenuPopover>

        </>
    );
}
