import { createContext, useContext, useState } from "react";

export const GeneralAppContext = createContext(null);

export const GeneralAppProvider = ({ children }) => {

    const [generalAppData, setGeneralAppData] = useState()

    return (
        <GeneralAppContext.Provider
            value={{ generalAppData, setGeneralAppData }}
        >
            {children}
        </GeneralAppContext.Provider>
    )

}


export const useGeneralAppContext = () => {
    const context = useContext(GeneralAppContext);

    if (!context) throw new Error('useGeneralAppContext context must be use inside GeneralAppProvider');

    return context;
};
