import { Button, Stack } from "@mui/material";
import React from "react";
import { useLocales } from "../../../locales/index.js";
import { SmsCampaignCardComponent } from "./SmsCampaignCardComponent.jsx";
import { LinearLoader } from "../../../components/linear-loader/LinearLoader.jsx";
import { EmptyBoard } from "../../common/EmptyBoard.jsx";
import { m } from "framer-motion";


export const SmsCampaignDataGrid = ({onSuccess, smsCampaignList}) => {
    const {translate} = useLocales()

    return (
        <>
            <m.div
                initial={{opacity: 0, x: -20}} // Initial state
                animate={{opacity: 1, x: 0}}   // Animation state
                exit={{opacity: 0, y: -20}}    // Exit state
                transition={{duration: 0.3}}   // Transition duration
            >
                <Stack alignItems="flex-end" sx={{my: 3}}>
                    <Button type="submit" variant="contained" onClick={onSuccess}>
                        {translate('dashboard.sms_campaign.start_create_sms_campaign')}
                    </Button>
                </Stack>
            </m.div>
            {smsCampaignList ?
                <m.div
                    initial={{opacity: 0, y: 20}} // Initial state
                    animate={{opacity: 1, y: 0}}   // Animation state
                    exit={{opacity: 0, y: -20}}    // Exit state
                    transition={{duration: 0.3}}   // Transition duration
                >
                    <Stack>
                        {smsCampaignList.length === 0
                            ? <EmptyBoard title="אין סמסים"/> :
                            smsCampaignList?.map((smsCampaign, index) => {
                                return (
                                    <SmsCampaignCardComponent key={index} smsCampaign={smsCampaign}/>
                                )
                            })}
                    </Stack>
                </m.div>
                : <LinearLoader parentSx={{mt: 32}}/>
            }
        </>
    )
}
