import { m } from "framer-motion";
import { Grid, Stack, Typography } from "@mui/material";
import NoEventsIllustration from "../../assets/illustrations/NoEventsIllustration";
import { MotionContainer, varBounce } from "../../components/minimal/animate";

export const EmptyBoard = ({ title }) => {

    return (
        <Grid item sx={{ margin: "20px auto" }}>
            <Stack alignItems="center" component={MotionContainer}>

                <m.div variants={varBounce().in}>
                    <Typography variant="h4" paragraph sx={{ color: 'text.secondary' }}>
                        {title}
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <NoEventsIllustration sx={{ my: 5 }}/>
                </m.div>

            </Stack>
        </Grid>
    )
}