import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { extractId } from "../utils/transformers";

export const MarketplaceContext = createContext(null);

MarketplaceContext.propTypes = {
    children: PropTypes.node,
};

export function MarketplaceProvider({ children }) {

    const [marketplace, setMarketplace] = useState()
    const [marketplaceAccountsSellers, setMarketplaceAccountsSellers] = useState([])
    const [marketplaceCharts, setMarketplaceCharts] = useState(null) // 'seats.io' charts which belongs to a marketplace
    const [marketplaceSaleStats, setMarketPlaceSaleStats] = useState(null)
    const [marketplaceGeneraleSaleStats, setMarketPlaceGeneraleSaleStats] = useState(null)

    return (
        <MarketplaceContext.Provider
            value={{
                marketplace,
                setMarketplace,
                marketplaceAccountsSellers,
                setMarketplaceAccountsSellers,
                marketplaceCharts,
                setMarketplaceCharts,
                marketplaceSaleStats,
                setMarketPlaceSaleStats,
                marketplaceGeneraleSaleStats,
                setMarketPlaceGeneraleSaleStats
            }}
        >
            {children}
        </MarketplaceContext.Provider>
    );
}

export const useMarketplaceContext = () => {
    const context = useContext(MarketplaceContext);

    if (!context) throw new Error('MarketplaceContext context must be use inside MarketplaceProvider');

    return context;
};
