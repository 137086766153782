import { useState } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink } from 'react-router-dom';
import { m } from 'framer-motion';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../routes/paths';
// auth
import { useAuthContext } from '../../context-state/AuthContext';
// components
import Iconify from '../../components/minimal/iconify';
import FormProvider, { RHFTextField } from '../../components/minimal/hook-form';
import {useLocales} from "../../locales";
import {MotionContainer, varFade} from "../../components/minimal/animate";
import * as API from "../../api";
import { AuthTypes } from "../../consts";
// ----------------------------------------------------------------------

export default function AuthLoginForm({ customAuthLogic }) {
  const { login } = useAuthContext();
  const { translate } = useLocales();
  const [isLoading, setIsLoading] = useState(false)

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().lowercase().trim().required(translate('dashboard.events.required_field')).email(translate('dashboard.events.invalid_email')),
    password: Yup.string().required(translate('dashboard.events.required_field')),
  });


  const defaultValues = { email: '', password: '' };
  const methods = useForm({ resolver: yupResolver(LoginSchema), defaultValues });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)
      const { email, password } = data
      // Meaning we don't want to go through the normal auth flow, instead we emit the authData to the parent component to handle the logic how it needs (like on accept-invitation flow)
      if (customAuthLogic) {
        const userData = await API.logIn({ email, password })
        customAuthLogic({ type: AuthTypes.LOGIN, data: userData.data })
        return
      }
      // Normal log in flow handled by the AuthContext
      await login(email, password);
    } catch (error) {
      const errorMsg = error?.message?.toLowerCase().includes('wrong') ? translate('auth.wrong_credentials') : translate('general_error')
      setError('afterSubmit', { ...error, message: errorMsg });
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack component={MotionContainer} >

          <Stack spacing={3} >
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
            <m.div variants={varFade().in}>
              <RHFTextField name="email" label="אימייל" />
            </m.div>
            <m.div variants={varFade().in}>
              <RHFTextField
                name="password"
                label="סיסמא"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </m.div>
          </Stack>

          <Stack alignItems="flex-center" sx={{ my: 2 }}>
            <m.div variants={varFade().in}>
              <Link
                component={RouterLink}
                to={PATH_AUTH.resetPassword}
                variant="body2"
                color="inherit"
                underline="always"
              >
              {translate('auth.forgot_password')}
              </Link>
            </m.div>
          </Stack>
          <m.div variants={varFade().in}>
            <LoadingButton
              fullWidth
              color="inherit"
              size="large"
              type="submit"
              variant="contained"
              loading={isLoading}
              sx={{
                bgcolor: 'text.primary',
                color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                '&:hover': {
                  bgcolor: 'text.primary',
                  color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                },
              }}
            >
              {translate('auth.login')}
            </LoadingButton>
          </m.div>
        </Stack>
    </FormProvider>
  );
}
