import { Helmet } from "react-helmet-async";
import React, { useState } from "react";
import { getPageTitleByDomain } from "../../../utils/market";
import {
    Container,
    IconButton,
} from "@mui/material";
import CustomBreadcrumbs from "../../../components/minimal/custom-breadcrumbs/index.jsx";
import { useSettingsContext } from "../../../components/minimal/settings/index.jsx";
import { useLocales } from "../../../locales/index.js";
import { CustomCard } from "../../common/event/common.jsx";
import { Steps } from "../../../components/Steps.jsx";
import { m } from "framer-motion";
import { SmsCampaignProperties } from "./SmsCampaignProperties.jsx";
import { SmsCampaignRecipients } from "./SmsCampaignRecipients.jsx";
import { SmsCampaignSummery } from "./SmsCampaignSummery.jsx";
import Iconify from "../../../components/minimal/iconify/index.jsx";
import { SmsCampaignDataGrid } from "./SmsCampaignDataGrid.jsx";
import { useSmsCampaignContext } from "../../../context-state/SmsCampaignContext.jsx";

const SmsCampaign = () => {

    const { themeStretch } = useSettingsContext();
    const { translate } = useLocales();
    const [activeStep, setActiveStep] = useState(0)
    const [smsCampaignPhoneNumbers, setSmsCampaignPhoneNumbers] = useState([])
    const [smsCampaignContent, setSmsCampaignContent] = useState({name: '', content: ''})
    const [smsCampaignPhoneNumbersManually, setSmsCampaignPhoneNumbersManually] = useState([])
    const [smsCampaignPhoneNumbersExcel, setSmsCampaignPhoneNumbersExcel] = useState([])
    const [smsCampaignPhoneNumbersSegment, setSmsCampaignPhoneNumbersSegment] = useState([])
    const [excelFile, setExcelFile] = useState()
    const STEPS = [translate('dashboard.sms_campaign.properties'), translate('dashboard.sms_campaign.recipients'), translate('dashboard.sms_campaign.summary')];

    const {smsCampaignContext} = useSmsCampaignContext()
    const {smsCampaignList, setSmsCampaignList} = smsCampaignContext;

    let stepToRender;


    const handleStartSendingSmsSuccess = () => {
        setSmsCampaignPhoneNumbers([])
        setSmsCampaignContent({name: '', content: ''})
        setSmsCampaignPhoneNumbersManually([])
        setSmsCampaignPhoneNumbersExcel([])
        setSmsCampaignPhoneNumbersSegment([])
        setActiveStep(1);
    };
    const handlePropertiesSuccess = (content, name) => {
        setSmsCampaignContent({content: content, name: name});
        setActiveStep(2);
    };

    const handleRecipientsSuccess = () => {
        const uniquePhoneNumbers = [...new Set([...smsCampaignPhoneNumbersManually, ...smsCampaignPhoneNumbersExcel, ...smsCampaignPhoneNumbersSegment])]; // remove duplicate numbers
        setSmsCampaignPhoneNumbers(uniquePhoneNumbers);
        setActiveStep(3);
    };

    const handleCreateNewSmsSuccess = (newSmsCampaignData) => {
        setSmsCampaignList([newSmsCampaignData, ...smsCampaignList]);
        setActiveStep(0);
    };

    switch (activeStep) {


        case 0:
            stepToRender =
                <SmsCampaignDataGrid onSuccess={handleStartSendingSmsSuccess} smsCampaignList={smsCampaignList}/>
            break;

        case 1:
            stepToRender =
                <SmsCampaignProperties onSuccess={handlePropertiesSuccess} smsCampaignContent={smsCampaignContent}/>
            break;
        case 2:
            stepToRender = <SmsCampaignRecipients
                onSuccess={handleRecipientsSuccess}
                smsCampaignPhoneNumbersManually={smsCampaignPhoneNumbersManually}
                setSmsCampaignPhoneNumbersManually={setSmsCampaignPhoneNumbersManually}
                smsCampaignPhoneNumbersExcel={smsCampaignPhoneNumbersExcel}
                setSmsCampaignPhoneNumbersExcel={setSmsCampaignPhoneNumbersExcel}
                smsCampaignPhoneNumbersSegment={smsCampaignPhoneNumbersSegment}
                setSmsCampaignPhoneNumbersSegment={setSmsCampaignPhoneNumbersSegment}
                excelFile={excelFile}
                setExcelFile={setExcelFile}
            />
            break;
        case 3:
            stepToRender = <SmsCampaignSummery onSuccess={handleCreateNewSmsSuccess}
                                               smsCampaignContent={smsCampaignContent}
                                               smsCampaignPhoneNumbers={smsCampaignPhoneNumbers}/>
    }

    return (
        <>
            <Helmet>
                <title>{getPageTitleByDomain("My SMS")}</title>
            </Helmet>
            <CustomBreadcrumbs heading={translate('sms')} links={[{name: ''}]}/>

            <Container container="true" maxWidth={themeStretch ? false : 'lg'}>
                {(activeStep !== 0) &&
                    <CustomCard sx={{margin: 2, paddingTop: 2}}>
                        {(activeStep === 1 || activeStep === 2 || activeStep === 3) &&

                            (
                                <IconButton
                                    onClick={() => {
                                        setActiveStep(activeStep - 1)
                                    }}
                                    sx={{width: 40, position: "absolute", height: 40, left: 10, top: 10, zIndex: 10}}
                                >
                                    <Iconify icon="eva:arrow-forward-outline"/>
                                </IconButton>
                            )
                        }

                        <m.div
                            initial={{scaleX: 0}}  // <-- Initial state
                            animate={{scaleX: 1}}  // <-- Animation state
                            transition={{duration: 0.5}}  // <-- Transition duration
                        >
                            <Steps initial={{opacity: 0, y: -80}} animate={{opacity: 1, y: 0}}
                                   transition={{duration: 0.4}} component={m.div} activeStep={activeStep - 1}
                                   steps={STEPS}/>
                        </m.div>
                    </CustomCard>
                }

                {stepToRender}
            </Container>
        </>
    )
}


export default SmsCampaign
