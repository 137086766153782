import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet-async";

// On the top of the Iphone, this what changes the color. see at - https://stackoverflow.com/questions/70856591/how-to-set-color-of-iphones-top-bar
// So we change it according to the theme mode.
export const MobileStyleHandler = () => {

    // <!-- PWA primary color -->
    // <!--  <meta name="theme-color" content="#00AB55" />-->

    const theme = useTheme();
    const [themeBackground, setThemeBackground] = useState(theme.palette.background.default)

    useEffect(() => {
        setThemeBackground(theme.palette.background.default)
    }, [theme.palette.mode]);

    return (
        <Helmet>
            <meta name="theme-color" content={themeBackground} />
        </Helmet>
    );
}