import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

export const SubscriptionContext = createContext(null);

SubscriptionProvider.propTypes = {
    children: PropTypes.node,
};

export function SubscriptionProvider({ children }) {
    return (
        <SubscriptionContext.Provider
            value={{
                SubscriptionPageContext: {
                },
            }}
        >
            {children}
        </SubscriptionContext.Provider>
    );
}

export const useSubscriptionContext = () => {
    const context = useContext(SubscriptionContext);

    if (!context) throw new Error('useSubscriptionContext context must be use inside SubscriptionProvider');

    return context;
};
