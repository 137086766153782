import { getAccountCollaborates, getUser } from "../api";

export const PermissionService = {
    async getAccountCollaboratorsData(ownerId, accountId) {

        const res = await getAccountCollaborates(accountId)
        const { collaborates, invitees } = res.data

        // TODO - what to do with this?
        let ownerData = { data: { user: "" } }
        try {
            ownerData = await getUser(ownerId)
        } catch (e) {
            console.log(e);
        }


        return {
            collaborates,
            invitees,
            owner: ownerData.data.user
        }

    }
}