import { Helmet } from "react-helmet-async";
import { Container, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDashboardContext } from "../../../context-state/DashboardContext";
import { LinksSection } from "./LinksSection";
import { getPageTitleByDomain } from "../../../utils/market";

export default function LinksPage() {

    const { eventContext } = useDashboardContext();
    const { getEvent } = eventContext
    const { eventId } = useParams();
    const event = getEvent(eventId)

    return (
        <>
            <Helmet>
                <title>{getPageTitleByDomain("Event Links")}</title>
            </Helmet>
            <Container maxWidth={false}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <LinksSection currentEvent={event} />
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
