import './config-window' // Keep it here to solve this issue - https://stackoverflow.com/questions/72114775/vite-global-is-not-defined
import ReactDOM from 'react-dom/client';
//
import App from './App';
import { APP_VERSION } from "./config-global";

window.tellMeVersion = () => {
    console.log(APP_VERSION);
}
// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);
