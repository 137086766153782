import { Link as RouterLink } from 'react-router-dom';
import { m } from 'framer-motion';
// @mui
import { Alert, Tooltip, Stack, Typography, Link, Box } from '@mui/material';
// auth
import { useAuthContext } from '../../context-state/AuthContext';
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import AuthLayout from '../../layouts/auth';
//
import AuthLoginForm from './AuthLoginForm';
import AuthWithSocial from './AuthWithSocial';
import { useLocales } from "../../locales";
import {MotionContainer, varFade} from "../../components/minimal/animate";

// ----------------------------------------------------------------------

export default function Login() {

    const { translate } = useLocales();

  return (
    <AuthLayout title={translate('auth.login_title')}>

      <Stack component={MotionContainer} spacing={2} sx={{ mb: 5, position: 'relative' }}>

          <m.div variants={varFade().in}>
            <Typography variant="h4">{translate('auth.login')}</Typography>
          </m.div>
          <m.div variants={varFade().in}>
            <Stack direction="row" spacing={0.5}>
            {/*  <Typography sx={{ marginLeft: "5px" }} variant="body2">{translate('auth.new_user')}?</Typography>*/}
            {/*  <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">*/}
            {/*      {translate('auth.join_us')}*/}
            {/*  </Link>*/}
            </Stack>
          </m.div>

        {/*<Tooltip title={method} placement="left">*/}
        {/*  <Box*/}
        {/*    component="img"*/}
        {/*    alt={method}*/}
        {/*    src={`/assets/icons/auth/ic_${method}.png`}*/}
        {/*    sx={{ width: 32, height: 32, position: 'absolute', right: 0 }}*/}
        {/*  />*/}
        {/*</Tooltip>*/}
      </Stack>

      {/*<Alert severity="info" sx={{ mb: 3 }}>*/}
      {/*  Use email : <strong>demo@minimals.cc</strong> / password :<strong> demo1234</strong>*/}
      {/*</Alert>*/}

        <AuthLoginForm />

        {/*<m.div variants={varFade().in}>*/}
        {/*    <AuthWithSocial />*/}
        {/*</m.div>*/}
    </AuthLayout>
  );
}
