import axios from 'axios';
// config
import { BASE_URL } from '../../config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: BASE_URL });

export const AUTH_HEADER_KEY = "jwt"

export const setAxiosAuth = (accessToken) => {
    axiosInstance.defaults.headers.common[AUTH_HEADER_KEY] = `${accessToken}`;
}

export  const removeAxiosAuth = () => {
    delete axiosInstance.defaults.headers.common[AUTH_HEADER_KEY];
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
