import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

export const TicketContext = createContext(null);

TicketContext.propTypes = {
    children: PropTypes.node,
};

export function TicketProvider({ children }) {

    const [account, setAccount] = useState(null)
    const [event, setEvent] = useState(null)
    const [eventOrder, setEventOrder] = useState(null)
    const [userEvents, setUserEvents] = useState([]) // the userEvent will includes the ticket entity inside it, this is how we get it from the server.

    return (
        <TicketContext.Provider
            value={{
                accountContext: { account, setAccount },
                eventContext: { event, setEvent },
                eventOrderContext: { eventOrder, setEventOrder },
                userEventContext: { userEvents, setUserEvents },
            }}
        >
            {children}
        </TicketContext.Provider>
    );
}

export const useTicketContext = () => {
    const context = useContext(TicketContext);

    if (!context) throw new Error('useTicketContext context must be use inside TicketProvider');

    return context;
};
