import { useSnackbar } from "../../../components/minimal/snackbar";
import { useLocales } from "../../../locales";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Box, Grid, Stack } from "@mui/material";
import FormProvider, { RHFAutocomplete, RHFTextField } from "../../../components/minimal/hook-form";
import { LoadingButton } from "@mui/lab";
import * as API from "../../../api";
import RHFSwitch from "../../../components/minimal/hook-form/RHFSwitch";
import { useParams } from "react-router-dom";
import { useDashboardContext } from "../../../context-state/DashboardContext";

export const LinkForm = ({ link, onSuccess, buttonText }) => {

    const { enqueueSnackbar } = useSnackbar();
    const { translate } = useLocales()
    const { subId, eventId } = useParams()
    const { ticketContext, linkContext } = useDashboardContext();
    const { getTicketsOfEvent, getTicket } = ticketContext
    const { updateManyLinks, updateEventToLinksRelation, updateLink } = linkContext

    const isUpdateMode = !!link?._id
    const currentTickets = []

    // TOOD - change this... map from name to id
    const tickets = getTicketsOfEvent(eventId)
        .map((id) => {
            const ticket = getTicket(id)
            if (link?.tickets?.length && link.tickets.includes(id)) {
                currentTickets.push(ticket.name)
            }
            return ticket.name
        })

    const TicketsSchema = Yup.object().shape({
        name: Yup.string().required(translate('dashboard.events.required_field')),
        tickets: Yup.array().min(1, translate('dashboard.events.required_field')),
        countTicketsAllowPerPurchase: Yup.number().min(1, translate('dashboard.events.required_field')),
    });

    const defaultValues = {
        name: link ? link.name : '',
        is_enabled: link ? link.is_enabled : true,
        tickets: currentTickets.length ? currentTickets : tickets,
        description: link ? link.description : '',
        countTicketsAllowPerPurchase: (link && link.countTicketsAllowPerPurchase) ? link.countTicketsAllowPerPurchase : 1,
    }

    if (!isUpdateMode) {
        defaultValues.amountToCreate = 1
    }


    const methods = useForm({ resolver: yupResolver(TicketsSchema), defaultValues });

    const { handleSubmit, formState: { isSubmitting } } = methods;

    const onSubmit = async (data) => {
        try {
            // i don't like this...
            // map from name to id because the AutoSelect component make problems...
            const tickets = getTicketsOfEvent(eventId).map(getTicket)
            const ticketsIds = data.tickets.map(ticketName => {
                const ticket = tickets.find(ticket => ticket.name === ticketName)
                return ticket._id
            })


            if (isUpdateMode) {
                link.name = data.name
                link.description = data.description
                link.is_enabled = data.is_enabled
                link.countTicketsAllowPerPurchase = data.countTicketsAllowPerPurchase ? data.countTicketsAllowPerPurchase : 1
                link.tickets = ticketsIds

                const updatedLinkResponse = await API.updateLinkOfEvent({ accountId: subId, eventId, linkId: link._id, linkData: link })
                updateLink(updatedLinkResponse.data)
                /* On Success update */
                enqueueSnackbar(translate('snackbar.link_updated_successfully'))
                onSuccess()
                return
            }

            // Create many links
            const amountToCreate = data.amountToCreate ? Number(data.amountToCreate) : 1
            const linksToCreate = []
            for (let i = 0; i < amountToCreate; i++) {
                linksToCreate.push({
                    name: `${data.name} #${i + 1}`,
                    description: data.description,
                    is_enabled: data.is_enabled,
                    countTicketsAllowPerPurchase: data.countTicketsAllowPerPurchase ? data.countTicketsAllowPerPurchase : 1,
                    tickets: ticketsIds,
                })
            }

            const createLinksResponse = await API.createManyLinksOfEvent({ accountId: subId, eventId, linksDataArr: linksToCreate })
            updateManyLinks(createLinksResponse.data)
            updateEventToLinksRelation(eventId, createLinksResponse.data)
            /* On Success create */
            enqueueSnackbar(translate('snackbar.link_created_successfully'));
            onSuccess()

        } catch (error) {
            enqueueSnackbar(translate('general_error'), { variant: 'error' })
            console.error(error);
        }
    };

    return (
        <FormProvider width="100%" methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Box
                        columnGap={2}
                        rowGap={1}
                        display="grid"
                        gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
                    >
                        <RHFTextField name="name" label="שם" />
                        <RHFTextField name="description" label="תיאור" />
                        <RHFTextField name="countTicketsAllowPerPurchase" type="number" label="מספר כרטיסים לרכישה" helperText="* מספר הכרטיסים הזמינים לרכישה עבור לינק זה" />
                        {
                            isUpdateMode ? null : <RHFTextField name="amountToCreate" type="number" label="כמות לינקים ליצירה" />
                        }
                        <RHFSwitch
                            sx={{ alignItems: 'center' }}
                            name="is_enabled"
                            label="פעיל"
                        />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <RHFAutocomplete
                            name="tickets"
                            label="כרטיסים"
                            multiple
                            freeSolo={false}
                            options={tickets}
                            helperText="* כרטיסים שיוצגו בעמוד"
                            ChipProps={{ size: 'medium' }}
                        />
                    </Box>

                    <Stack alignItems="flex-end" sx={{ my: 3 }}>
                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                            {buttonText}
                        </LoadingButton>
                    </Stack>
                </Grid>
            </Grid>
        </FormProvider>
    )
}
