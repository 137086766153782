import axios, { AUTH_HEADER_KEY } from "../utils/minimal/axios";
import Axios from 'axios';

/* ------------------ User ------------------ */
export const logIn = async ({ email, password }) => {
    return axios.post('/user/login', { email, password })
}

export const verifyToken = async (token) => {
    return axios.get('/user/verifyToken', { headers: { [AUTH_HEADER_KEY]: token } })
}

export const updateSeasonTicketsDiscount = async ({ accountId, eventId, data }) => {
    return axios.put(`/event/updateEventSeasonTicketDiscount/${accountId}/${eventId}`, data)
}

export const getAccountsByJwt = async (token) => {
    return axios.get('/user/account/getByJWT', { headers: { [AUTH_HEADER_KEY]: token } })
}

export const getUser = async (userId) => {
    return axios.get(`/user/${userId}`)
}

export const getAccountCollaborates = (accountId) => {
    return axios.get(`/user/account/getCollaboratorsAndInvitees/${accountId}`)
}

export const register = async (userData) => {
    return axios.post('/user/register', userData)
}

export const updateUser = async (userData) => {
    return axios.put('/user/update', userData)
}

export const forgotPassword = async (forgotPasswordData) => {
    return axios.post('/user/forgotPassword', forgotPasswordData)
}

export const updatePassword = async (data) => {
    return axios.put('/user/updatePassword', data)
}

export const logOut = async () => {
    const data = await axios.put('/user/logout')
}

export const getAccount = async (accountId, token) => {
    return axios.get(`/user/account/${accountId}`, { headers: { [AUTH_HEADER_KEY]: token } })
}

export const getAccountSecured = async (accountId) => {
    return axios.get(`/user/account/${accountId}`)
}


export const getAccountIdByHandler = async (handler) => {
    return axios.get(`/user/account/getByHandler/${handler}`)
}

export const createSubAccount = async ({ accountId, subAccountData }) => {
    return axios.post(`/user/account/createSubAccount/${accountId}`, subAccountData)
}

export const updateAccount = async ({ accountId, subAccountData }) => {
    return axios.put(`/user/account/updateAccount/${accountId}`, subAccountData)
}

export const getSubAccounts = async (accountId, token) => {
    return axios.get(`/user/account/subAccounts/${accountId}`, { headers: { [AUTH_HEADER_KEY]: token } })
}

export const inviteCollaborator = async ({ accountId, collaborateData }) => {
    return axios.post(`/user/account/inviteCollaborator/${accountId}`, collaborateData)
}

export const acceptInvitation = async (inviteeToken, token) => {
    return axios.post("/user/account/acceptInvitation", { token: inviteeToken }, { headers: { [AUTH_HEADER_KEY]: token } })
}

export const updateCollaborate = async ({ accountId, userId, role }) => {
    return axios.post(`/user/account/updateCollaborator/${accountId}`, { userId, role })
}

export const deleteCollaborate = async ({ accountId, userId }) => {
    return axios.post(`/user/account/deleteCollaborator/${accountId}`, { userId })
}

export const getMarketplaceOfUser = (token) => {
    return axios.get("/user/marketplace", { headers: { [AUTH_HEADER_KEY]: token } })
}

export const getMarketplaceById = (marketplaceId) => {
    return axios.get(`/user/marketplace/${marketplaceId}`)
}

export const getAccountsOfMarketplace = (marketPlaceId) => {
    return axios.get(`/user/marketplace/accounts/${marketPlaceId}`)
}

export const createAccountUnderMarketplace = (marketPlaceId, accountData) => {
    return axios.post(`/user/account/createAccount/${marketPlaceId}`, accountData)
}

export const updateAccountWithMarketplaceFinancial = (marketPlaceId, accountId) => {
    return axios.put(`/user/account/updateAccountWithMarketplaceFinancial/${marketPlaceId}/${accountId}`)
}

export const createNewSellerPartner = (accountSellerData) => {
    return axios.post(`/user/seller-partner/create`, accountSellerData)
}

export const getEndUserById = ({ marketplaceId, accountId, endUserId }) => {
    return axios.get(`/user/enduser/getById/${marketplaceId}/${accountId}/${endUserId}`)
}

export const getEndUserCRMEvents = ({ marketplaceId, sellerId, endUserId }) => {
    return axios.get(`/crm/getEndUsersCRMEventByEndUser/${marketplaceId}/${sellerId}/${endUserId}`)
}

// By 'account' we mean the parent account (seller) and not sub account
export const getEndUsersOfAccount = ({ marketplaceId, accountId, page = 1, limit = 500 }) => {
    return axios.get(`/user/enduser/getByAccount/${marketplaceId}/${accountId}?page=${page}&limit=${limit}`)
}

export const aggregateEndUsersBySellerByMonthOrSpecificDates = ({ marketplaceId, sellerId, startDate, endDate }) => {
    return axios.get(`/user/enduser/aggregateEndUsersBySellerByMonthOrSpecificDates/${marketplaceId}/${sellerId}?startDate=${startDate}&endDate=${endDate}`)
}

export const getSegmentsOfAccount = ({ marketplaceId, accountId }) => {
    return axios.get(`/user/segment/getByAccount/${marketplaceId}/${accountId}`)
}

export const createSegment = ({ marketplaceId, accountId, segmentData }) => {
    return axios.post(`/user/segment/create/${marketplaceId}/${accountId}`, segmentData)
}

export const editSegment = ({ marketplaceId, accountId, segmentId, segmentData }) => {
    return axios.put(`/user/segment/update/${marketplaceId}/${accountId}/${segmentId}`, segmentData)
}

export const getEndUsersOfSegment = ({ marketplaceId, accountId, segmentId, shouldPopulateEndUser = false }) => {
    let url = `/user/segment/getEndUsersBySegmentId/${marketplaceId}/${accountId}/${segmentId}`
    if (shouldPopulateEndUser) {
        url += '?shouldPopulateEndUser=true'
    }
    return axios.get(url)
}

export const assignEndUsersToSegment = ({ marketplaceId, accountId, segmentId, endUsersIds }) => {
    return axios.post(`/user/segment/addEndUsers/${marketplaceId}/${accountId}/${segmentId}`, { endUsers: endUsersIds })
}

export const syncSegmentWithEndUsers = ({ marketplaceId, accountId, segmentId }) => {
    return axios.put(`/user/segment/syncSegmentWithEndUsers/${marketplaceId}/${accountId}/${segmentId}`)
}

export const removeEndUsersFromSegment = ({ marketplaceId, accountId, segmentId, endUsersIds }) => {
    return axios.post(`/user/segment/removeEndUsers/${marketplaceId}/${accountId}/${segmentId}`, { endUsers: endUsersIds })
}

export const getAccountByIdWithToken = (accountId, token) => {
    return axios.get(`/user/account/${accountId}`, { headers: { [AUTH_HEADER_KEY]: token } })
}

export const getAccountById = (accountId) => {
    return axios.get(`/user/account/${accountId}`)
}

export const updateEndUser = ({ marketplaceId, accountId, endUserId, endUserData }) => {
    return axios.put(`/user/enduser/update/${marketplaceId}/${accountId}/${endUserId}`, endUserData)
}

export const createEndUser = ({ marketplaceId, accountId, endUserData }) => {
    return axios.post(`/user/enduser/create/${marketplaceId}/${accountId}`, endUserData)
}

export const searchEndUsersByTerm = ({ marketplaceId, accountId, term, page = 1, limit = 500, segments }) => {
    let url = `/user/enduser/searchByTerm/${marketplaceId}?limit=${limit}&page=${page}&accountId=${accountId}&term=${term}`
    // convert segments to string
    let segmentsString = ''
    if (segments && segments.length) {
        segmentsString = segments.join(',')
        url += `&segments=${segmentsString}`
    }
    return axios.get(url)
}

export const getSubAccountsByParentId = (accountId) => {
    return axios.get(`/user/account/subAccountsByParentId/${accountId}`)
}


/* ------------------ Event ------------------ */
export const getEventById = async ({ accountId, eventId }) => {
    return axios.get(`/event/getById/${accountId}/${eventId}`)
}

export const getAccountEvents = async (accountId) => {
    return axios.get(`/event/getEventsOfAccount/${accountId}`)
}

export const getMarketPlaceEvents = async (marketplaceId) => {
    return axios.get(`/event/getEventsByMarketplace/${marketplaceId}`)
}

export const getSellerEvents = async (sellerId) => {
    return axios.get(`/event/getEventsBySeller/${sellerId}`)
}

export const getEvent = async ({ accountId, eventId }) => {
    return axios.get(`/event/getById/${accountId}/${eventId}`)
}

export const createEvent = async ({ accountId, eventData }) => {
    return axios.post(`/event/createEvent/${accountId}`, eventData)
}

export const updateEvent = async ({ accountId, eventId, eventData }) => {
    return axios.put(`/event/updateEvent/${accountId}/${eventId}`, eventData)
}

export const deleteEvent = async ({ accountId, eventId }) => {
    const data = await axios.delete(`/event/deleteEvent/${accountId}/${eventId}`)
}

export const updateEventTicket = async ({ accountId, eventId, ticketId, ticketData }) => {
    return axios.put(`/event/ticket/update/${accountId}/${eventId}/${ticketId}`, ticketData)
}

export const fetchEventTickets = async ({ accountId, eventId, nimiFeeType }) => {
    let url = `/event/ticket/getTickets/${accountId}/${eventId}`
    if (nimiFeeType) {
        url += `?nimiFeeType=${nimiFeeType}`
    }
    return axios.get(url)
}

// This is will give the full ticket's data
export const fetchEventTicketsSecure = async ({ accountId, eventId }) => {
    return axios.get(`/event/ticket/getTicketsSecured/${accountId}/${eventId}`)
}

export const createEventTicket = async ({ accountId, eventId, ticketData }) => {
    return axios.post(`/event/ticket/create/${accountId}/${eventId}`, ticketData)
}

export const getEventByHandler = async ({ accountId, eventHandler, utm }) => {
    let url = `/event/getEventByHandler/${accountId}/${eventHandler}`
    if (utm) {
        url += `?utm=${utm}`
    }
    return axios.get(url)
}
// get event by id
export const getEventJustById = async ({ eventId }) => {
    return axios.get(`/event/getPublicEventById/${eventId}`)
}

export const checkIn = async ({ accountId, eventId, userEventId, ticketInfoId }) => {
    return axios.put(`/user-event/web-qr-check-in/${accountId}/${eventId}/${userEventId}/${ticketInfoId}`)
}

export const getTicketById = async ({ accountId, eventId, ticketId }) => {
    return axios.get(`/event/ticket/getById/${accountId}/${eventId}/${ticketId}`)
}

// Should not be in use any more after we changes to eventLinks entity instead of having it on the event itself as an array.
export const updateOrCreateEventLinks = async ({ accountId, eventId, linksArray }) => {
    return axios.put(`/event/updateOrCreateEventLinks/${accountId}/${eventId}`, linksArray)
}

export const applySeatsIoChartToEvent = async ({ accountId, eventId, seatsChartKey }) => {
    return axios.post(`/event/createSeatsEventAndUpdateEvent/${accountId}/${eventId}/${seatsChartKey}`)
}

export const deleteTicket = async ({ accountId, eventId, ticketId }) => {
    return axios.delete(`/event/ticket/delete/${accountId}/${eventId}/${ticketId}`)
}

export const getLinksOfEventsSecured = async ({ accountId, eventId }) => {
    return axios.get(`/event/event-link/getEventLinksSecured/${accountId}/${eventId}`)
}

export const getLinksOfEvents = async ({ accountId, eventId }) => {
    return axios.get(`/event/event-link/getEventLinks/${accountId}/${eventId}`)
}

export const getLinksOfEventsByUtm = async ({ accountId, eventId, utm }) => {
    return axios.get(`/event/event-link/getEventLinksOfEventByUTMUnsecured/${accountId}/${eventId}/${utm}`)
}

export const createLinkOfEvent = async ({ accountId, eventId, linkData }) => {
    return axios.post(`/event/event-link/create/${accountId}/${eventId}`, linkData)
}

export const createManyLinksOfEvent = async ({ accountId, eventId, linksDataArr }) => {
    return axios.post(`/event/event-link/createMany/${accountId}/${eventId}`, linksDataArr)
}

export const updateLinkOfEvent = async ({ accountId, eventId, linkId, linkData }) => {
    return axios.put(`/event/event-link/update/${accountId}/${eventId}/${linkId}`, linkData)
}

export const deleteLinkOfEvent = async ({ accountId, eventId, linkId }) => {
    return axios.delete(`/event/event-link/delete/${accountId}/${eventId}/${linkId}`)
}

export const getPublicEventsOfAccount = (accountId) => {
    return axios.get(`/event/getPublicEventsByAccountId/${accountId}`)
}

/* ------------------ User Event ------------------ */
export const getUserEventsByEndUser = ({ accountId, endUserId }) => {
    return axios.get(`/user-event/getByEndUser/${accountId}/${endUserId}`)
}

export const createUserEvent = async ({ accountId, eventId, ticketId, userEvent }) => {
    return axios.post(`/user-event/create/${accountId}/${eventId}/${ticketId}`, userEvent)
}

export const createEventOrder = async ({ accountId, eventId, eventOrder }) => {
    return axios.post(`/user-event/event-order/create/${accountId}/${eventId}`, eventOrder)
}

export const getAccountUserEvent = async ({ accountId }) => {
    const data = await axios.get(`/user-event/getUserEventsOfAccount/${accountId}`)
}

export const updateUserEvent = async ({ accountId, eventId, userEventId, userEventData }) => {
    return axios.put(`/user-event/updateUserEvent/${accountId}/${eventId}/${userEventId}`, userEventData)
}

export const getUserEventById = async ({ accountId, eventId, userEventId }) => {
    const data = await axios.get(`/user-event/getById/${accountId}/${eventId}/${userEventId}`)
}

export const getUserEventsOfEvent = async ({ accountId, eventId }) => {
    return axios.get(`/user-event/getUserEventsOfEvent/${accountId}/${eventId}`)
}

export const getEventOrdersOfEvent = async ({ accountId, eventId }) => {
    return axios.get(`/user-event/event-order/getEventOrdersOfEvent/${accountId}/${eventId}`)
}

export const getEventOrderToDisplayTickets = (eventOrderId) => {
    return axios.get(`/user-event/event-order/getEventOrderToDisplayTickets/${eventOrderId}`)
}

// This is legacy
export const checkInUser = ({ accountId, eventId, userEventId }) => {
    return axios.put(`/user-event/check-in/${accountId}/${eventId}/${userEventId}`)
}
// This is new (per ticket info)
export const checkInUserQr = ({ accountId, eventId, userEventId, ticketInfoId }) => {
    return axios.put(`/user-event/qr-check-in/${accountId}/${eventId}/${userEventId}/${ticketInfoId}`)
}

export const resendEmail = ({ accountId, eventId, eventOrderId }) => {
    return axios.post(`/user-event/event-order/reSendEmail/${accountId}/${eventId}/${eventOrderId}`)
}

export const updateEventOrder = ({ accountId, eventId, eventOrderId, orderEventData }) => {
    return axios.put(`/user-event/event-order/updateEventOrder/${accountId}/${eventId}/${eventOrderId}`, orderEventData)
}

export const getEventOrdersOfByUserEvents = ({ marketplaceId, accountId, userEventsIds }) => {
    return axios.post(`/user-event/event-order/getByUserEventsIds/${marketplaceId}/${accountId}`, { userEventsIds })
}

export const getTotalOrdersEarnedBySellerGroupsByEvent = ({ marketplaceId, sellerId }) => {
    return axios.get(`/user-event/event-order/aggregateTotalEarnedBySellerGroupedByEvent/${marketplaceId}/${sellerId}`)
}

export const getTotalOrdersEarnedBySeller = ({ marketplaceId, sellerId, startDate, endDate, byMarketplace }) => {
    let url = `/user-event/event-order/aggregateTotalEarnedBySeller/${marketplaceId}/${sellerId}`
    if (startDate && endDate) {
        url += `?startDate=${startDate}&endDate=${endDate}`
    }
    if (byMarketplace) {
        url += url.includes('?') ? "&byMarketplace=true" : `?byMarketplace=true`
    }
    return axios.get(url)
}

export const getEventOrdersOfAccount = ({ accountId, isSeller = true, limit = 10 }) => {
    let url = `/user-event/event-order/getEventOrdersOfAccount/${accountId}?limit=${limit}`
    if (isSeller) {
        url += `&isSeller=${isSeller}`
    }
    return axios.get(url)
}

/* ------------------ Payment ------------------ */
export const generateSale = async ({ accountId, eventId, paymentToken, token }) => {
    return axios.post(`/payment/generate-sale/${accountId}/${eventId}`, {}, { headers: { 'authorization-payment': paymentToken, [AUTH_HEADER_KEY]: token } })
}

export const getEventCoupons = async ({ accountId, eventId }) => {
    return axios.get(`/payment/coupon/getByEvent/${accountId}/${eventId}`)
}

export const getCouponByCode = async ({ accountId, eventId, code }) => {
    return axios.get(`/payment/coupon/getByCode/${accountId}/${eventId}?code=${code}`)
}

export const getCouponsByAccount = async ({ accountId }) => {
    return axios.get(`/payment/coupon/getByAccount/${accountId}`)
}

export const createCoupon = async ({ accountId, eventId, couponData }) => {
    if (!eventId) {
        return axios.post(`/payment/coupon/create/${accountId}`, couponData)
    }
    return axios.post(`/payment/coupon/create/${accountId}/${eventId}`, couponData)
}

export const updateCoupon = async ({ couponId, accountId, couponData }) => {
    return axios.put(`/payment/coupon/update/${accountId}/${couponId}`, couponData)
}

export const deleteCoupon = async ({ accountId, couponId }) => {
    return axios.delete(`/payment/coupon/delete/${accountId}/${couponId}`)
}

// Legacy
export const generateRefund = async ({ accountId, eventId, refundData }) => {
    return axios.post(`/payment/refund-sale/${accountId}/${eventId}`, refundData)
}

// New
export const refund = async ({ accountId, eventId, refundData }) => {
    return axios.post(`/payment/refund/${accountId}/${eventId}`, refundData)
}

export const getChargesOfEventOrder = async ({ accountId, eventId, eventOrderId }) => {
    return axios.get(`/payment/charge/getByEventOrder/${accountId}/${eventId}/${eventOrderId}`)
}

export const uploadSellerDocs = ({ accountId, filesData }) => {
    return axios.post(`/payment/partner/uploadDocuments/${accountId}`, filesData)
}

export const getOrderPrice = ({ accountId, orderData }) => {
    return axios.post(`/payment/getPrice/${accountId}`, orderData)
}

export const createSeasonTicket = ({ marketplaceId, sellerId, seasonTicketData }) => {
    return axios.post(`/payment/seasonTicket/create/${marketplaceId}/${sellerId}`, seasonTicketData)
}

export const updateSeasonTicket = ({ marketplaceId, sellerId, seasonTicketId, seasonTicketData }) => {
    return axios.put(`/payment/seasonTicket/update/${marketplaceId}/${sellerId}/${seasonTicketId}`, seasonTicketData)
}

export const getSeasonTicketsOfSeller = ({ marketplaceId, sellerId }) => {
    return axios.get(`/payment/seasonTicket/getBySellerId/${marketplaceId}/${sellerId}`)
}

export const getEndUserSeasonTickets = ({ marketplaceId, sellerId, endUserId }) => {
    return axios.get(`/payment/endUserSeasonTicket/getByEndUserId/${marketplaceId}/${sellerId}/${endUserId}`)
}

export const createEndUserSeasonTicket = ({ marketplaceId, sellerId, endUserId, endUserSeasonTicketData }) => {
    return axios.post(`/payment/endUserSeasonTicket/create/${marketplaceId}/${sellerId}/${endUserId}`, endUserSeasonTicketData)
}

export const leanCreateEndUserSeasonTicket = ({ sellerId, endUserId, endUserSeasonTicketData }) => {
    return axios.post(`/payment/endUserSeasonTicket/createLean/${sellerId}/${endUserId}`, endUserSeasonTicketData)
}

export const getEndUserSeasonTicketByHrc = async ({ marketplaceId, sellerId, eventId, hrc }) => {
    return axios.get(`/payment/endUserSeasonTicket/getByHrc/${marketplaceId}/${sellerId}/${eventId}?hrc=${hrc}`)
}

export const getTotalSeasonTicketsEarningBySellerId = async ({ marketplaceId, sellerId, startDate, endDate }) => {
    return axios.get(`/payment/endUserSeasonTicket/totalEarningBySellerId/${marketplaceId}/${sellerId}?startDate=${startDate}&endDate=${endDate}`)
}

/* ------------------ Analytics ------------------ */
export const getUtmsOfEvent = ({ accountId, eventId }) => {
    return axios.get(`/analytics/utm/getUtmsOfEvent/${accountId}/${eventId}`)
}

export const incUtm = ({ accountId, eventId, utmData }) => {
    return axios.put(`/analytics/utm/inc/${accountId}/${eventId}`, utmData)
}


/* ----------------- SMS Campaign ------------------*/


export const sendSms = ({ marketplaceId, smsData, sellerId }) => {
    return axios.post(`/send-notification/sms/send/${marketplaceId}/${sellerId}`, smsData)
}
// /send-notification/sms/smsCampaign/
export const getSmsCampaignByAccountId = ({ accountId }) => {
    return axios.get(`/send-notification/sms/smsCampaign/account/${accountId}`)
}

export const getSmsCampaignBySellerId = ({ sellerId }) => {
    return axios.get(`/send-notification/sms/smsCampaign/seller/${sellerId}`)
}

export const getSmsCampaignByMarketplaceId = ({ marketplaceId }) => {
    return axios.get(`/send-notification/sms/smsCampaign/${marketplaceId}`)
}

export const getPublicEvents = async ({ type, id }) => {
    return axios.get(`/event/public-events/${type}/${id}`)
}

export const updateMarketplace = async ({ marketplaceId, data }) => {
    return axios.put(`/user/marketplace/update/${marketplaceId}`, data)
}