import PropTypes from 'prop-types';
//
import Image from '../../image';
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

SingleFilePreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default function SingleFilePreview({ file }) {
  if (!file) {
    return null;
  }

  const imgUrl = typeof file === 'string' ? file : file.preview;
  const isPdfFile = file.path?.toLowerCase()?.endsWith('.pdf') || imgUrl.toLowerCase()?.endsWith('.pdf')

  if (isPdfFile) {
      return (
              <Box sx={{ width: "100%", height: "100%", position: "absolute", top: 0, }}>
                  <embed type="application/pdf" src={imgUrl + '#toolbar=0&navpanes=0&scrollbar=0'} width="100%" height="100%"/>
              </Box>
      )
  }


  return (
    <Image
      alt="file preview"
      src={imgUrl}
      sx={{
        top: 8,
        left: 8,
        zIndex: 8,
        borderRadius: 1,
        position: 'absolute',
        width: 'calc(100% - 16px)',
        height: 'calc(100% - 16px)',
      }}
    />
  );
}
