import { useAuthContext } from "../../../context-state/AuthContext";
import { usePermissionContext } from "../../../context-state/PermissionContext";
import { useEffect } from "react";
import { PermissionService } from "../../../services/permissionService";
import { LinearLoader } from "../../../components/linear-loader/LinearLoader";

// Fetch all relevant permission data before continue to the collaborates page.
export const CollaboratesGuard = ({ children }) => {

    const { account } = useAuthContext();
    const { collaboratesContext, inviteesContext, ownerContext } = usePermissionContext();
    const { collaboratesByAccount, setCollaboratesToAccount } = collaboratesContext
    const { setInviteesToAccount } = inviteesContext
    const { setAccountOwner } = ownerContext

    const collaboratesForThisAccount = collaboratesByAccount[account._id]

    const getCollaboratesDataForThisAccount = async () => {
        try {
            const { collaborates, invitees, owner } = await PermissionService.getAccountCollaboratorsData(account.owner, account._id)
            if (!collaborates) {
                throw Error('Failed get collaborates')
            }
            // Invitees
            setInviteesToAccount(account._id, invitees)
            // Collaborates
            setCollaboratesToAccount(account._id, collaborates)
            // Owner
            setAccountOwner(owner)
        } catch (error) {
            console.error(error);
            // updateCollaboratesToAccountRelation(account._id, [])
        }
    }

    useEffect(() => {
        if (!collaboratesForThisAccount) {
            getCollaboratesDataForThisAccount()
        }
    }, [collaboratesForThisAccount])

    return (
        collaboratesForThisAccount ? children : <LinearLoader />
    )
}