import { useMarketplaceContext } from "../../../../context-state/MarketplaceContext";
import * as API from "../../../../api";
import { LinearLoader } from "../../../../components/linear-loader/LinearLoader";
import { useSnackbar } from "../../../../components/minimal/snackbar";
import { useEffect } from "react";

export const MySellersGuard = ({ children }) => {

    const { marketplace, marketplaceAccountsSellers, setMarketplaceAccountsSellers } =  useMarketplaceContext()
    const { enqueueSnackbar } = useSnackbar();

    const getMarketPlaceData = async () => {
        try {
            const marketplaceSellersData = await API.getAccountsOfMarketplace(marketplace._id)
            setMarketplaceAccountsSellers(marketplaceSellersData.data)
        } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' })
            console.error(e);
        }
    }

    useEffect(() => {
        if (!marketplaceAccountsSellers?.length) {
            getMarketPlaceData()
        }
    }, [])

    return (
        marketplaceAccountsSellers?.length ? children : <LinearLoader />
    )
}
