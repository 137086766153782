export const sortEventsByStartData = (events) => {
    try {
        return events.sort((a, b) => new Date(a.start_date).getTime() > new Date(b.start_date).getTime() ? 1 : -1)
    } catch (e) {
        console.log("sort error! sortEventsByStartData");
        console.log(e);
        return events
    }
}

export const isFutureEvent = (event) => {
    const now = new Date()
    now.setHours(0, 0, 0, 0);
    const eventStateDate = new Date(event.end_date ? event.end_date : event.start_date)
    eventStateDate.setHours(0, 0, 0, 0);
    return eventStateDate.getTime() >= now.getTime()
}


export const isPastEvent = (event) => {
    const now = new Date()
    now.setHours(0, 0, 0, 0);
    const eventStateDate = new Date(event.start_date)
    eventStateDate.setHours(0, 0, 0, 0);
    return eventStateDate.getTime() < now.getTime()
}
