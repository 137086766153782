
const maxMale = 11
const maxFemale = 13

export const getUserAvatarPath = (index = 1, gender = "male") => {

    if (Number.isNaN(index)) {
        console.log('no a number!');
        index = 1
    }

    return `/assets/images/avatars/${gender}/avatar_${index}.jpg`
}

export const getRandomUserAvatarByGender = (gender) => {
    if (!gender) {
        gender = Math.random() < 0.5 ? 'male' : 'female'
    }
    const maxRange = gender === 'male' ? maxMale : maxFemale
    const randomIndex = Math.floor(Math.random() * (maxRange - 1 + 1) + 1)
    return `/assets/images/avatars/${gender}/avatar_${randomIndex}.jpg`
}