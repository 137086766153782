// routes
import { PATH_DASHBOARD } from './routes/paths';

// General
export const APP_VERSION = import.meta.env.VITE_APP_VERSION
export const APP_ENV = import.meta.env.VITE_ENV

// API
// ----------------------------------------------------------------------
export const BASE_URL = import.meta.env.VITE_BASE_URL;

// API
// ----------------------------------------------------------------------
export const SENTRY_DNS = import.meta.env.VITE_SENTRY_DNS;
export const HELIOS_API_TOKEN = import.meta.env.VITE_HELIOS_API_TOKEN;

// AWS
// ----------------------------------------------------------------------
export const AWS_ACCESS_KEY_ID = import.meta.env.VITE_AWS_ACCESS_KEY_ID;
export const AWS_SECRET_ACCESS_KEY = import.meta.env.VITE_AWS_SECRET_ACCESS_KEY;
export const AWS_BUCKET_NAME = import.meta.env.VITE_AWS_BUCKET_NAME;
export const AWS_BUCKET_BASE_URL = import.meta.env.VITE_AWS_BUCKET_BASE_URL;
export const AWS_CLOUDFROND_BASE_URL = import.meta.env.VITE_AWS_CLOUDFROND_BASE_URL;
export const AWS_REGION = import.meta.env.VITE_AWS_REGION;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.main;
// export const PATH_AFTER_LOGIN = PATH_DASHBOARD.sub.root;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

// PAYMENT
export const LOCAL_TRANS_FEE = 1.1
export const REFUND_FEE_PERCENTAGE = 5
