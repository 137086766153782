import useResponsive from "../../hooks/minimal/useResponsive";
import { LinearProgress, Stack } from "@mui/material";

export const LinearLoader = ({ parentSx = {} }) => {
    const isMobile = useResponsive('down', 'lg');
    return (
        <Stack justifyContent="center" alignItems="center" height={isMobile ? "80vh" : "100%"} sx={{ ...parentSx }}>
            <LinearProgress color="inherit" sx={{ width: 1, maxWidth: isMobile ? "80%" : 360 }} />
        </Stack>
    )
}
