import { useAuthContext } from "../../../context-state/AuthContext";
import { useDashboardContext } from "../../../context-state/DashboardContext";
import { getAccountEvents } from "../../../api";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { sortEventsByStartData } from "../../../utils/sort";
import { LinearLoader } from "../../../components/linear-loader/LinearLoader";

// Fetch all events before continue to the events routes
export const AllEventsGuard = () => {

    const { account } = useAuthContext();
    const { eventContext } = useDashboardContext();
    const { setEvents } = eventContext
    const [isFetched, setIsFetched] = useState(false)

    const getAllEvents = async () => {
        try {
            const response = await getAccountEvents(account._id);
            setEvents(sortEventsByStartData(response.data))
        } catch (error) {
            console.error(error);
            setEvents([]);
        } finally {
            setIsFetched(true)
        }
    }

    useEffect(() => {
        getAllEvents()
    }, [account])


    return (
        isFetched ? <Outlet /> : <LinearLoader />
    )
}
