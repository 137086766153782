import { getAccountEvents, getAccountsOfMarketplace, getEventOrdersOfEvent } from "../api";

export const MarketService = {
    async getMarketPlaceSaleStatsData(marketPlaceId) {

        const marketplaceAccounts = await getAccountsOfMarketplace(marketPlaceId)
        const eventsPromises = marketplaceAccounts.data.map(account => getAccountEvents(account._id))
        let events = await Promise.all(eventsPromises)
        events = events.map(eventData => eventData.data).flat()
        const eventOrdersPromises = events.map(event => getEventOrdersOfEvent({ accountId: event.account, eventId: event._id }))
        let eventOrders = await Promise.all(eventOrdersPromises)
        eventOrders = eventOrders.map(eventOrderData => eventOrderData.data).flat()

        return {
            accounts: marketplaceAccounts.data,
            events,
            eventOrders
        }

    }
}
