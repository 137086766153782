import { Stack, Typography } from "@mui/material";
import Label from "../../../components/minimal/label/index.jsx";
import Iconify from "../../../components/minimal/iconify/index.jsx";
import useResponsive from "../../../hooks/minimal/useResponsive.jsx";
import { useDashboardContext } from "../../../context-state/DashboardContext.jsx";

export const OverrideMsg = () => {

    const isMobile = useResponsive('down', 'sm');
    const { overrideMsgContext } = useDashboardContext()
    const { dashboardOverrideMsg } = overrideMsgContext

    if (!dashboardOverrideMsg) {
        return null
    }

    return (
       <Stack sx={{ textAlign: 'center', zIndex: 99999, position: "fixed", justifyContent: "center", width: '100%' }} direction="row">
           <Label variant="filled" color="error" sx={{ display: "flex", gap: 1, alignItems: 'center', p: 2, borderRadius: isMobile ? 0 : 1, minWidth: isMobile ? "100%" : "unset" }}>
               <Iconify icon="eva:alert-circle-outline" />
               <Typography variant="body2">
                   {dashboardOverrideMsg}
               </Typography>
           </Label>
       </Stack>
    )
}
