import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../../context-state/AuthContext';
// components
import { CustomAvatar } from '../../../components/minimal/custom-avatar';
import { getUserAvatarPath } from "../../../utils/userAvatar";
import { useDashboardContext } from "../../../context-state/DashboardContext";
import { varSlide } from "../../../components/minimal/animate";
import { PATH_DASHBOARD } from "../../../routes/paths";

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 2),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();
  const userName = `${user?.lname} ${user?.fname}`

  const userImage = user?.imageUrl ? user?.imageUrl : getUserAvatarPath()

  return (
      <Link component={RouterLink} to={PATH_DASHBOARD.profile} underline="none" color="inherit">
        <StyledRoot>
          <CustomAvatar src={userImage} alt={userName} name={userName} />

          <Box sx={{ ml: 2, minWidth: 0 }}>
            <Typography sx={{ textTransform: 'capitalize' }} variant="subtitle2" noWrap>
              {user?.lname} {user?.fname}
            </Typography>

            <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
              {user?.role}
            </Typography>
          </Box>
        </StyledRoot>
      </Link>
  );
}
