import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Card, Stack, Typography } from "@mui/material";
import { m } from "framer-motion";
import { varSlide } from "../../../components/minimal/animate/index.jsx";
import { useLocales } from "../../../locales/index.js";


export const SmsCampaignCardComponent = ({smsCampaign}) => {
    const {translate} = useLocales()
    const [showFullContent, setShowFullContent] = useState(false);


    let formattedDateAsString;
    if (smsCampaign.createdAt) {
        const formattedDate = new Date(smsCampaign.createdAt);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };
        formattedDateAsString = formattedDate.toLocaleDateString('he-IL', options);
    }

    const toggleContentVisibility = () => {
        setShowFullContent(!showFullContent);
    };
    return (
        <>

            <Card
                component={m.div}
                variants={varSlide({durationIn: 0.4, distance: 150}).inUp}
                sx={{p: 2, m: 1, position: 'relative'}}
            >
                <Accordion
                    component={m.div}
                    variants={varSlide({durationIn: 0.4, distance: 150}).inUp}
                    sx={{boxShadow: "none !important", background: "transparent !important"}}
                    onClick={smsCampaign?.content?.length > 50 ? toggleContentVisibility : undefined}
                >
                    <AccordionSummary
                        sx={{".MuiAccordionSummary-content": {justifyContent: "space-between", alignItems: "center"}}}>
                        <Stack sx={{display: 'flex'}}>
                            <Typography sx={{display: 'flex', cursor: "pointer"}} variant="h6">
                                {translate('dashboard.sms_campaign.sms_campaign_name')}<Typography
                                sx={{cursor: 'pointer', alignSelf: 'center', paddingLeft: 1}}
                                variant="body1"> {smsCampaign.name}</Typography>
                            </Typography>
                            <Typography sx={{display: 'flex', cursor: "pointer"}} variant="h6">
                                {translate('dashboard.sms_campaign.sms_campaign_counter_number')} <Typography
                                sx={{cursor: 'pointer', alignSelf: 'center', paddingLeft: 1}}
                                variant="body1"> {smsCampaign.sentCounter}</Typography>
                            </Typography>
                            {formattedDateAsString &&
                                <Typography sx={{display: 'flex', cursor: "pointer"}} variant="h6">
                                    {translate('dashboard.sms_campaign.sms_campaign_created_at')}
                                    <Typography sx={{cursor: 'pointer', alignSelf: 'center', paddingLeft: 1}}
                                                variant="body1">{formattedDateAsString}</Typography>
                                </Typography>}
                            {!showFullContent && <Typography sx={{display: 'flex', cursor: 'pointer'}} variant="h6">
                                {translate('dashboard.sms_campaign.sms_campaign_content')}
                                <Typography sx={{cursor: 'pointer', alignSelf: 'center', paddingLeft: 1}}
                                            variant="body1">
                                    {smsCampaign?.content?.length > 50
                                        ? `${smsCampaign.content.slice(0, 50)}...`
                                        : smsCampaign.content}
                                </Typography>
                            </Typography>}
                        </Stack>
                    </AccordionSummary>
                    {
                        showFullContent && <AccordionDetails>
                            <Typography sx={{display: 'flex', cursor: 'pointer'}} variant="h6">
                                {translate('dashboard.sms_campaign.sms_campaign_content')}
                                <Typography sx={{cursor: 'pointer', alignSelf: 'center', paddingLeft: 1}} variant="body1">
                                    {smsCampaign.content}
                                </Typography>
                            </Typography>
                        </AccordionDetails>
                    }
                </Accordion>
            </Card>

        </>
    )
}
