import { useParams } from "react-router-dom";
import { useDashboardContext } from "../context-state/DashboardContext";
import { useAuthContext } from "../context-state/AuthContext";

export const useEventLink = () => {
    const { eventId } = useParams()
    const { eventContext } = useDashboardContext()
    const { account } = useAuthContext()
    const { getEvent } = eventContext
    const event = getEvent(eventId)

    let pathname = window.location.hostname
    pathname = pathname === 'localhost' ? `http://${pathname}:3030` : `https://${pathname}`

    return `${pathname}/event/${account.handler}/${event.handler}`
}