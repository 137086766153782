export function extractSpecificCsvFormatPhoneNumbers(csvData) {
    const rows = csvData.split('\n'); // Split by newline
    const arrayResult = [];

    // Find the header row and get the index of the column 'מספרי פלאפון'
    const headerRow = rows[0].split(',');
    const phoneIndex = headerRow.findIndex(column => column.trim() === 'מספר טלפון');

    if (phoneIndex !== -1) {
        // If 'מספרי פלאפון' is found
        rows.slice(1).forEach(row => {
            const values = row.split(',');
            if (values[phoneIndex]) {
                const numbers = values[phoneIndex]
                arrayResult.push(numbers);
            }
        });
    } else {
        // If 'מספרי פלאפון' is not found, split by comma
        rows.forEach(row => {
            const values = row.split(',');
            arrayResult.push(...values);
        });
    }

    return arrayResult;
}