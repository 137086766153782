import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { extractId } from "../utils/transformers";

export const PermissionContext = createContext(null);

PermissionContext.propTypes = {
    children: PropTypes.node,
};

export function PermissionProvider({ children }) {

    // const [collaboratesMap, setCollaboratesMap] = useState({})
    // const [inviteesMap, setInviteesMap] = useState({})
    // const [accountToCollaboratesMap, setAccountToCollaboratesMap] = useState({})
    // const [accountToInviteesMap, setAccountToInvitees] = useState({})

    /* Collaborates */
    // const getCollaborate = (id) => collaboratesMap[id]
    // const getCollaboratesOfAccount = (accountId) => accountToCollaboratesMap[accountId]
    // const updateCollaborator = (collaborate) => {
    //     const newCollaboratesMap = JSON.parse(JSON.stringify(collaboratesMap))
    //     newCollaboratesMap[collaborate._id] = collaborate
    //     setCollaboratesMap(newCollaboratesMap)
    // }
    // const updateManyCollaborates = (collaborates) => {
    //     const newCollaboratesMap = JSON.parse(JSON.stringify(collaboratesMap))
    //     collaborates.forEach(collaborate => { newCollaboratesMap[collaborate._id] = collaborate })
    //     setCollaboratesMap(newCollaboratesMap)
    // }
    // const updateCollaboratesToAccountRelation = (accountId, collaborates) => {
    //     const ids = collaborates.map(extractId)
    //     const newAccountToCollaboratesMap = JSON.parse(JSON.stringify(accountToCollaboratesMap))
    //     const newArray = newAccountToCollaboratesMap[accountId]?.length ? [...newAccountToCollaboratesMap[accountId], ...ids] : ids
    //     newAccountToCollaboratesMap[accountId] = [ ...new Set(newArray)] // Force to remove duplications
    //     setAccountToCollaboratesMap(newAccountToCollaboratesMap)
    // }

    /* Invites */
    // const getInvitee = (id) => inviteesMap[id]
    // const getInviteesOfAccount = (accountId) => accountToInviteesMap[accountId]
    // const updateInvitee = (invitee) => {
    //     const newInviteesMap = JSON.parse(JSON.stringify(inviteesMap))
    //     newInviteesMap[invitee._id] = invitee
    //     setInviteesMap(newInviteesMap)
    // }
    // const updateManyInvitees = (invitees) => {
    //     const newInviteesMap = JSON.parse(JSON.stringify(inviteesMap))
    //     invitees.forEach(invitee => { newInviteesMap[invitee._id] = invitee })
    //     setInviteesMap(newInviteesMap)
    // }
    // const updateInviteesToAccountRelation = (accountId, invitees) => {
    //     const ids = invitees.map(extractId)
    //     const newAccountToInviteesMap = JSON.parse(JSON.stringify(accountToInviteesMap))
    //     const newArray = newAccountToInviteesMap[accountId]?.length ? [...newAccountToInviteesMap[accountId], ...ids] : ids
    //     newAccountToInviteesMap[accountId] = [ ...new Set(newArray)] // Force to remove duplications
    //     setAccountToInvitees(newAccountToInviteesMap)
    // }


    // Currently only this in use
    const [collaboratesByAccount, setCollaboratesByAccount] = useState({})
    const [inviteesByAccount, setInviteesByAccount] = useState({})
    const [accountOwner, setAccountOwner] = useState(null)

    const setCollaboratesToAccount = (accountId, collaborates) => {
        const newCollaboratesByAccount = JSON.parse(JSON.stringify(collaboratesByAccount))
        newCollaboratesByAccount[accountId] = collaborates
        setCollaboratesByAccount(newCollaboratesByAccount)
    }

    const setInviteesToAccount = (accountId, invitees) => {
        const newInviteesByAccount = JSON.parse(JSON.stringify(inviteesByAccount))
        newInviteesByAccount[accountId] = invitees
        setInviteesByAccount(newInviteesByAccount)
    }

    return (
        <PermissionContext.Provider
            value={{
                collaboratesContext: { collaboratesByAccount, setCollaboratesToAccount },
                inviteesContext: { inviteesByAccount, setInviteesToAccount },
                ownerContext: { accountOwner, setAccountOwner }
            }}
        >
            {children}
        </PermissionContext.Provider>
    );
}

export const usePermissionContext = () => {
    const context = useContext(PermissionContext);

    if (!context) throw new Error('usePermissionContext context must be use inside PermissionProvider');

    return context;
};
