import { Helmet } from 'react-helmet-async';
// sections
import Login from './Login';
import { getPageTitleByDomain } from "../../utils/market";
// import Login from '../../sections/auth/LoginAuth0';

// ----------------------------------------------------------------------

export default function LoginPage() {
  return (
    <>
      <Helmet>
          <title>{getPageTitleByDomain("Login")}</title>
      </Helmet>

      <Login />
    </>
  );
}
